import { Select, Stack, Text } from "@mantine/core";
import { Dispatch, SetStateAction } from "react";

export function Embedding({
  embeddingState,
  setEmbeddingState,
}: {
  embeddingState: string;
  setEmbeddingState: Dispatch<SetStateAction<string>>;
}) {
  const embeddingOptions = [
    {
      value: "text-embedding-3-small",
      label: "text-embedding-3-small (OpenAI)",
    },
    { value: "MiniLM-L6-v2", label: "MiniLM-L6-v2 (local inference)" },
  ];
  return (
    <Stack>
      <Select
        label="Select vector embedding model"
        placeholder="Select type"
        w="20rem"
        data={embeddingOptions}
        onChange={(_value) => {
          if (_value !== null) {
            setEmbeddingState(_value);
          }
        }}
        value={embeddingState}
        required
        allowDeselect={false}
      />
      {embeddingState === "text-embedding-3-small" && (
        <Text c="dimmed" fz="xs">
          Configure your OpenAI API key directly in the ETL pipeline code
          created.
        </Text>
      )}
      {embeddingState === "MiniLM-L6-v2" && (
        <Text c="dimmed" fz="xs">
          Dependencies for local inference will be installed to use MiniLM. This
          may take up to 5 minutes.
        </Text>
      )}
    </Stack>
  );
}
