import axios, { AxiosError } from "axios";
import { OptionsState } from "./types";
const API_BASE_URL = process.env.REACT_APP_API_URL || "https://api.aryn.cloud";

export const initialOptionsState: OptionsState = {
  auto_threshold: true,
  threshold: 0.32,
  use_ocr: false,
  ocr_images: false,
  ocr_tables: false,
  extract_table_structure: false,
  extract_images: false,
  selected_pages: [[1, 1]],
  source: "web",
};

export const partitionDocument = async (
  file: File | Blob,
  apiKey: string,
  options: OptionsState = initialOptionsState
) => {
  const formData = new FormData();
  formData.append("pdf", file);
  const processedOptions = { ...options };
  if (processedOptions.auto_threshold) {
    processedOptions.threshold = "auto";
  }
  delete processedOptions.auto_threshold;
  formData.append("options", JSON.stringify(processedOptions));
  const response = await axios.post(
    `${API_BASE_URL}/v1/document/partition`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    }
  );
  if (response.status !== 200) {
    if (response.data.detail) {
      throw new Error(response.data.detail);
    }
    throw new Error("API request failed");
  }
  return response;
};

export const decryptPdf = async (file: File, ApiKey: string) => {
  const formData = new FormData();
  formData.append("pdf", file);
  const response = await axios.post(
    `${API_BASE_URL}/v1/document/decrypt`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${ApiKey}`,
      },
    }
  );
  if (response.status !== 200) {
    if (response.data.detail) {
      throw new Error(response.data.detail);
    }
    throw new Error("API request failed");
  }
  const decryptedData = response.data.pdfData;
  return decryptedData;
};

export async function checkAPIKey(ApiKey: string): Promise<boolean> {
  try {
    const formData = new FormData();
    formData.append("pdf", "no-pdf");
    await axios.post(
      `${API_BASE_URL}/v1/document/partition`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${ApiKey}`,
        },
      }
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        if (axiosError.response.status === 422) {
          return true;
        } else if (axiosError.response.status === 401) {
          return false;
        } else {
          return false;
        }
      }
    }
  }
  return false;
}

export async function sendFeedback(
  rating: number,
  comment: string,
  fileHash: string,
  options: OptionsState,
  apiKey: string,
  callId: string
) {
  const response = await axios.post(
    `${API_BASE_URL}/v1/feedback`,
    {
      rating: rating,
      comment: comment,
      pdf_hash: fileHash,
      options: options,
      aryn_call_id: callId,
    },
    {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    }
  );
  console.log(response);

  if (response.status !== 200) {
    if (response.data.detail) {
      throw new Error(response.data.detail);
    }
    throw new Error("API request failed");
  }

  return true;
}

export const convertToPDF = async (file: File, ApiKey: string) => {
  const formData = new FormData();
  formData.append("input_file", file);
  const response = await axios.post(
    `${API_BASE_URL}/v1/document/to-pdf`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${ApiKey}`,
      },
      responseType: "blob",
    }
  );
  if (response.status !== 200) {
    if (response.data.detail) {
      throw new Error(response.data.detail);
    }
    throw new Error("API request failed");
  }
  const pdfData = response.data;
  const blob = new Blob([pdfData], { type: "application/pdf" });
  const pdfFile = new File([blob], "converted.pdf", {
    type: "application/pdf",
  });

  return pdfFile;
};

export const uploadToDrive = async (notebook: any, ApiKey: string) => {
  const notebookJson = JSON.stringify(notebook, null, 2);
  const blob = new Blob([notebookJson], { type: "application/json" });

  const file = new File([blob], "sycamore_pipeline.ipynb", {
    type: "application/json",
  });

  const formData = new FormData();
  formData.append("file", file);
  const response = await axios.post(
    `${API_BASE_URL}/v1/file/upload`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${ApiKey}`,
      },
    }
  );
  if (response.status !== 200) {
    if (response.data.detail) {
      throw new Error(response.data.detail);
    }
    throw new Error("API request failed");
  }
  return response;
};
