import { Dispatch, SetStateAction } from "react";
import { Anchor, Group, Select, Stack, Text } from "@mantine/core";

interface Step3State {
  strategy: string | null;
  maxTokens: number | undefined;
}

export function Chunking({
  step3State,
  setStep3State,
}: {
  step3State: Step3State;
  setStep3State: Dispatch<SetStateAction<Step3State>>;
}) {
  const chunkingOptions = [
    {
      label: "Greedy Text Element Merger",
      value: "GreedyTextElementMerger",
      link: "https://sycamore.readthedocs.io/en/latest/sycamore/transforms/merge.html#greedy-text-element-merger",
    },
    {
      label: "Greedy Section Merger",
      value: "GreedySectionMerger",
      link: "https://sycamore.readthedocs.io/en/latest/sycamore/transforms/merge.html#greedy-section-merger",
    },
    {
      label: "Marked Merger",
      value: "MarkedMerger",
      link: "https://sycamore.readthedocs.io/en/latest/sycamore/transforms/merge.html#marked-merger",
    },
  ];

  const selectedOption = chunkingOptions.find(
    (option) => option.value === step3State.strategy
  );

  return (
    <Stack m="lg" p="lg" gap="lg">
      <Text fw={500} fz="h3">
        Select chunking strategy
      </Text>
      <Group gap="lg">
        <Select
          label="Chunking strategy"
          placeholder="Select strategy"
          data={chunkingOptions}
          w="20rem"
          onChange={(_value) => {
            setStep3State((prev) => ({
              ...prev,
              strategy: _value,
            }));
          }}
          value={step3State.strategy}
          allowDeselect={false}
        />
        {/* <NumberInput
          label="Enter token limit"
          placeholder="512"
          w="20rem"
          value={step3State.maxTokens}
          onChange={(_value) => {
            console.log(_value);

            setStep3State((prev) => ({
              ...prev,
              maxTokens: Number(_value),
            }));
          }}
        /> */}
      </Group>
      {selectedOption && (
        <Text size="sm">
          Learn more about the{" "}
          <Anchor href={selectedOption.link} target="_blank" c="#0073bb">
            {selectedOption.label}
          </Anchor>
          .
        </Text>
      )}
    </Stack>
  );
}
