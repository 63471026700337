import {
  Alert,
  Anchor,
  Button,
  Card,
  Dialog,
  Divider,
  Group,
  Loader,
  Modal,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import PDFViewer from "./PDFViewer";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import Options from "./Options";
import {
  IconAlertCircle,
  IconDownload,
  IconMessage,
  IconReload,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  decryptPdf,
  initialOptionsState,
  partitionDocument,
} from "../../../apiService";
import { OptionsState } from "../../../types";
import {
  generatePdf,
  processAnnotations,
} from "../../../utils/partitionerUtils";
import FeedbackModal from "./FeedbackModal";
import { tour } from "../../../tour/tour";
import ReactGA from "react-ga4";

const PdfViewerComponent = ({
  file,
  setFile,
  options,
  setOptions,
  jsonData,
  setJsonData,
  boxes,
  setBoxes,
  openErrorDialog,
  callId,
  setCallId,
  pageCount,
  apiKey,
  originalFile,
  cookies,
  setCookie,
  convertedFile,
}: {
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  options: OptionsState;
  setOptions: Dispatch<SetStateAction<OptionsState>>;
  jsonData: any;
  setJsonData: Dispatch<SetStateAction<any>>;
  boxes: any;
  setBoxes: Dispatch<SetStateAction<any>>;
  openErrorDialog: (message: string, timeout?: number) => void;
  callId: string;
  setCallId: Dispatch<SetStateAction<string>>;
  pageCount: number | null;
  apiKey: string;
  originalFile: File | null;
  cookies: any;
  setCookie: any;
  convertedFile: File | null;
}) => {
  const theme = useMantineTheme();
  const mobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [feedbackOpened, feedbackHandlers] = useDisclosure();
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [downloadingJson, setDownloadingJson] = useState<boolean>(false);
  const [downloadingPdf, setDownloadingPdf] = useState<boolean>(false);
  const stackRef = useRef<HTMLDivElement>(null);
  const [stackHeight, setStackHeight] = useState<number>(0);
  const [dialogOpened, dialogHandler] = useDisclosure(false);
  const [feedbackOptions, setFeedbackOptions] = useState<OptionsState>(options);
  const navigate = useNavigate();

  useEffect(() => {
    if (stackRef.current) {
      const height = stackRef.current.getBoundingClientRect().height;
      setStackHeight(height);
    }
  }, []);

  useEffect(() => {
    if (cookies["_wixAB3|*"] === "true") {
      setTimeout(() => {
        tour.start();
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 6);
        setCookie("_wixAB3|*", false, {
          path: "/",
          expires: expirationDate,
          secure: true,
          sameSite: "strict",
        });
      }, 6000);
    }
  }, []);

  const openSuccessDialog = () => {
    dialogHandler.open();
    setTimeout(() => {
      dialogHandler.close();
    }, 4000);
  };

  const handleReloadBoxes = async () => {
    if (!file || !apiKey || !pageCount || !originalFile) return;
    if (mobileScreen) {
      setDrawerState(false);
    }
    setPdfLoading(true);
    try {
      const response = await partitionDocument(originalFile, apiKey, options);
      const arynCallId = response.headers["x-aryn-call-id"];
      console.log("Request Id: ", arynCallId);
      if (response.data.error) {
        console.log(response.data.error);
        openErrorDialog("Please try again after sometime.");
      } else {
        if (arynCallId) {
          setCallId(arynCallId);
        }
        setFeedbackOptions(options);
        setJsonData(response.data.elements);
        setBoxes(processAnnotations(response.data));
        const newBoxes = processAnnotations(response.data);
        let newBlob;
        try {
          let existingPdfBytes;
          if (originalFile.type === "application/pdf") {
            existingPdfBytes = await originalFile.arrayBuffer();
          } else {
            if (!convertedFile) return;
            existingPdfBytes = await convertedFile.arrayBuffer();
          }
          newBlob = await generatePdf(existingPdfBytes, newBoxes, pageCount);
        } catch (error: any) {
          if (error.message.includes("encrypted")) {
            console.log("Encrypted PDF detected!");
            try {
              const decryptedBlob = await decryptPdf(originalFile, apiKey);
              newBlob = await generatePdf(decryptedBlob, newBoxes);
            } catch (error) {
              console.error("An error occurred while decrypting:", error);
              openErrorDialog("Something wrong happened while decrypting");
            }
          } else {
            console.error("An error occurred while drawing boxes:", error);
            openErrorDialog("Something wrong happened while drawing boxes");
          }
        }
        if (newBlob) {
          setFile(new File([newBlob], file.name, { type: "application/pdf" }));
        }
      }
    } catch (error: any) {
      console.log(error);
      openErrorDialog(
        "Something wrong happened while reloading boxes " + error?.message
      );
    } finally {
      setPdfLoading(false);
    }
  };

  // const handleThumbUp = () => setRating((prev) => (prev === 1 ? 0 : 1));

  // const handleThumbDown = () => setRating((prev) => (prev === -1 ? 0 : -1));

  const handleDownloadJson = async () => {
    if (!jsonData || !file) return;
    ReactGA.event({
      category: "DocParse",
      action: "[DocParse]download json",
    });
    setDownloadingJson(true);
    try {
      const json = JSON.stringify(jsonData, null, 2);
      const blob = new Blob([json], { type: "application/json" });
      const href = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = href;
      link.download =
        file.name.substring(0, file.name.lastIndexOf(".")) + "-bbox.json";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.error("error downloading json", e);
      openErrorDialog("Something wrong happened while downloading json");
    } finally {
      setDownloadingJson(false);
      if (mobileScreen) {
        setDrawerState(false);
      }
    }
  };

  const handleDownloadPdf = async () => {
    if (!boxes || !file || !apiKey || !pageCount) return;
    ReactGA.event({
      category: "DocParse",
      action: "[DocParse]download pdf",
    });
    setDownloadingPdf(true);
    try {
      const newBlob = file;
      if (newBlob) {
        const href = URL.createObjectURL(newBlob as Blob);

        const link = document.createElement("a");
        link.href = href;
        link.download =
          file.name.substring(0, file.name.lastIndexOf(".")) + "-bbox.pdf";
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    } finally {
      setDownloadingPdf(false);
      if (mobileScreen) {
        setDrawerState(false);
      }
    }
  };

  return (
    <>
      <Dialog
        opened={dialogOpened}
        size="lg"
        radius="md"
        position={{ right: 10, top: 10 }}
        p="none"
        zIndex="1000"
      >
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          color="green"
          withCloseButton
          onClose={dialogHandler.close}
        >
          Feedback submitted
        </Alert>
      </Dialog>
      <Group justify="space-between">
        <PDFViewer
          file={file}
          boxes={boxes}
          pdfLoading={pdfLoading}
          setDrawerState={setDrawerState}
          optionsHeight={stackHeight}
          pageCount={pageCount}
        />

        {!mobileScreen && (
          <Stack w="16vw" justify="flex-start" py="md" ref={stackRef} gap="xs">
            <Paper
              shadow="xs"
              p="md"
              style={{ borderRadius: 10, backgroundColor: "#f2f2f2" }}
            >
              <Text size="md" fw={600} ta="center">
                Next steps?{" "}
              </Text>
              <Text size="sm" ta="center">
                Use{" "}
                <Anchor
                  c="#0073bb"
                  onClick={() => {
                    ReactGA.event({
                      category: "DocParse",
                      action: "[DocParse]redirect to DocPrep",
                    });
                    navigate("/docprep");
                  }}
                >
                  DocPrep
                </Anchor>{" "}
                to chunk and load the partitioned document into a vector
                database.
              </Text>
            </Paper>
            <Paper
              shadow="xs"
              p="md"
              style={{ borderRadius: 10, backgroundColor: "#f2f2f2" }}
            >
              {/* <Text size="md" fw={600} ta="center">
                Python integration
              </Text> */}
              <Text size="sm" ta="center">
                Use the{" "}
                <Anchor
                  c="#0073bb"
                  href="https://sycamore.readthedocs.io/en/stable/aryn_cloud/accessing_the_partitioning_service.html#using-aryn-sdk"
                  target="_blank"
                  onClick={() => {
                    ReactGA.event({
                      category: "DocParse",
                      action: "[DocParse]redirect to SDK docs",
                    });
                  }}
                >
                  Aryn Python SDK
                </Anchor>{" "}
                to integrate Aryn DocParse in your workflow. Check out an
                example
                <Anchor
                  c="#0073bb"
                  href="https://colab.research.google.com/drive/1Qpd-llPC-EPzuTwLfnguMnrQk0eclyqJ"
                  target="_blank"
                  onClick={() => {
                    ReactGA.event({
                      category: "DocParse",
                      action: "[DocParse]redirect to SDK example colab",
                    });
                  }}
                >
                  {" "}
                  in a notebook.
                </Anchor>{" "}
              </Text>
            </Paper>
            <Options
              options={options}
              setOptions={setOptions}
              handleReloadBoxes={handleReloadBoxes}
              pdfLoading={pdfLoading}
            />
            <Stack gap="xs" px="xs">
              <Divider pb="xs" />
              <Button
                variant="outline"
                onClick={handleDownloadJson}
                rightSection={downloadingJson && <Loader size="sm" />}
                leftSection={<IconDownload />}
                disabled={downloadingJson || pdfLoading}
              >
                JSON output
              </Button>
              <Button
                variant="outline"
                onClick={handleDownloadPdf}
                rightSection={downloadingPdf && <Loader size="sm" />}
                disabled={downloadingPdf || pdfLoading}
                leftSection={<IconDownload />}
              >
                Partitioned PDF
              </Button>
            </Stack>
            <Stack px="xs" pt="xs">
              <Divider />
              <Card p={0} radius="md" shadow="sm">
                <Button
                  onClick={() => {
                    setOptions(initialOptionsState);
                    setFile(null);
                  }}
                  variant="transparent"
                  leftSection={<IconReload />}
                  h="3rem"
                >
                  Start over
                </Button>
              </Card>
            </Stack>
            <Stack gap="xs" px="xs" pt="xs">
              <Divider />
              <Button
                onClick={feedbackHandlers.open}
                variant="transparent"
                leftSection={<IconMessage />}
                p={0}
              >
                Share feedback
              </Button>
            </Stack>
          </Stack>
        )}
      </Group>
      <Modal
        opened={drawerState}
        onClose={() => setDrawerState(false)}
        title="Options"
        zIndex={1000}
        size="lg"
        styles={{
          title: {
            fontWeight: 650,
          },
        }}
      >
        <Stack justify="flex-start" gap="lg">
          <Options
            options={options}
            setOptions={setOptions}
            handleReloadBoxes={handleReloadBoxes}
            pdfLoading={pdfLoading}
          />
          <Divider my="sm" />
          <Button
            variant="outline"
            onClick={handleDownloadJson}
            rightSection={downloadingJson && <Loader size="sm" />}
            disabled={downloadingJson || pdfLoading}
          >
            Download JSON
          </Button>
          <Button
            variant="outline"
            onClick={handleDownloadPdf}
            rightSection={downloadingPdf && <Loader size="sm" />}
            disabled={downloadingPdf || pdfLoading}
          >
            Download PDF
          </Button>
          <Divider my="sm" />
          <Card p={0} radius="md" shadow="sm">
            <Button
              onClick={() => {
                setOptions(initialOptionsState);
                setFile(null);
              }}
              variant="transparent"
              leftSection={<IconReload />}
              h="3rem"
            >
              Start over
            </Button>
          </Card>
          <Divider my="xs" />
          <Button
            onClick={() => {
              feedbackHandlers.open();
              setDrawerState(false);
            }}
            variant="transparent"
            leftSection={<IconMessage />}
            p={0}
          >
            Share feedback
          </Button>
        </Stack>
      </Modal>
      <FeedbackModal
        opened={feedbackOpened}
        onClose={feedbackHandlers.close}
        file={file}
        callId={callId}
        options={feedbackOptions}
        openSuccessDialog={openSuccessDialog}
        openErrorDialog={openErrorDialog}
        apiKey={apiKey}
      />
    </>
  );
};
export default PdfViewerComponent;
