import { useState } from "react";
import {
  Modal,
  Button,
  Textarea,
  Text,
  Group,
  ActionIcon,
} from "@mantine/core";
import {
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUp,
  IconThumbUpFilled,
} from "@tabler/icons-react";
import { sendFeedback } from "../../../apiService";
import { calculateFileHash } from "../../../utils/partitionerUtils";
import { OptionsState } from "../../../types";

function FeedbackModal({
  opened,
  onClose,
  file,
  callId,
  options,
  openSuccessDialog,
  openErrorDialog,
  apiKey,
}: {
  opened: boolean;
  onClose: () => void;
  file: File | null;
  callId: string;
  options: OptionsState;
  openSuccessDialog: () => void;
  openErrorDialog: (message: string, timeout?: number) => void;
  apiKey: string;
}) {
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");

  const handleThumbUp = () => setRating((prev) => (prev === 1 ? 0 : 1));

  const handleThumbDown = () => setRating((prev) => (prev === -1 ? 0 : -1));

  const handleFeedbackSubmit = async () => {
    if (!apiKey || !file) return;
    try {
      const hash = await calculateFileHash(file);
      await sendFeedback(
        rating,
        comment,
        hash as string,
        options,
        apiKey,
        callId
      );
      setRating(0);
      setComment("");
      openSuccessDialog();
    } catch (error) {
      console.error("An error occurred while submitting feedback:", error);
      openErrorDialog("Something wrong happened while submitting feedback");
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Feedback"
      centered
      styles={{
        title: {
          fontSize: "1.2rem",
        },
      }}
    >
      <Group>
        <Text>Rating: </Text>
        <ActionIcon variant="transparent" onClick={handleThumbUp}>
          {rating === 1 ? <IconThumbUpFilled /> : <IconThumbUp />}
        </ActionIcon>
        <ActionIcon variant="transparent" onClick={handleThumbDown}>
          {rating === -1 ? <IconThumbDownFilled /> : <IconThumbDown />}
        </ActionIcon>
      </Group>

      <Textarea
        placeholder="Leave your feedback here..."
        value={comment}
        onChange={(event) => setComment(event.currentTarget.value)}
        minRows={3}
        mt="md"
      />

      <Button
        onClick={handleFeedbackSubmit}
        mt="md"
        disabled={rating === 0 && comment === ""}
        fullWidth
      >
        Submit
      </Button>
    </Modal>
  );
}

export default FeedbackModal;
