import React from "react";
import { Modal, Code, ActionIcon, Tooltip } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { IconCheck, IconClipboard } from "@tabler/icons-react";

const JsonModal = ({
  opened,
  close,
  jsonData,
}: {
  opened: boolean;
  close: () => void;
  jsonData: any;
}) => {
  const clipboard = useClipboard({ timeout: 1000 });

  return (
    <Modal opened={opened} onClose={close} title="JSON Data" size="xl">
      <Code block style={{ whiteSpace: "pre-wrap", position: "relative" }}>
        <Tooltip label={clipboard.copied ? "Copied" : "Copy"} position="bottom">
          <ActionIcon
            bg="#fff0"
            c="#00000082"
            style={{ position: "absolute", top: 0, right: 0, margin: "10px" }}
            onClick={() => clipboard.copy(JSON.stringify(jsonData, null, 2))}
          >
            {!clipboard.copied ? (
              <IconClipboard stroke={2} />
            ) : (
              <IconCheck stroke={2} />
            )}
          </ActionIcon>
        </Tooltip>

        {JSON.stringify(jsonData, null, 2)}
      </Code>
    </Modal>
  );
};

export default JsonModal;
