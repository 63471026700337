import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  ActionIcon,
  Button,
  Center,
  Group,
  Image,
  Radio,
  ScrollArea,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { Elasticsearch } from "../databases/Elasticsearch";
import { Pinecone } from "../databases/Pinecone";
import { Weaviate } from "../databases/Weaviate";

export function Database({
  step4State,
  setStep4State,
  showDBErrors,
  setShowDBErrors,
  embeddingState,
}: {
  step4State: {
    targetDatabase: string | null;
    fields: any;
  };
  setStep4State: Dispatch<SetStateAction<any>>;
  showDBErrors: boolean;
  setShowDBErrors: Dispatch<SetStateAction<boolean>>;
  embeddingState: string;
}) {
  const handleInputChange = (field: string, value: string | boolean) => {
    setStep4State((prevState: any) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: value,
      },
    }));
  };
  const vectorDimensions =
    embeddingState === "text-embedding-3-small"
      ? 1536
      : embeddingState === "MiniLM-L6-v2"
      ? 384
      : 384;

  const renderDatabaseFields = () => {
    switch (step4State.targetDatabase) {
      case "Elasticsearch":
        return (
          <Elasticsearch
            step4State={step4State}
            setStep4State={setStep4State}
            handleInputChange={handleInputChange}
            showDBErrors={showDBErrors}
            setShowDBErrors={setShowDBErrors}
          />
        );
      case "Pinecone":
        return (
          <Pinecone
            step4State={step4State}
            setStep4State={setStep4State}
            handleInputChange={handleInputChange}
            showDBErrors={showDBErrors}
            setShowDBErrors={setShowDBErrors}
          />
        );
      case "Weaviate":
        return (
          <Weaviate
            step4State={step4State}
            setStep4State={setStep4State}
            handleInputChange={handleInputChange}
            showDBErrors={showDBErrors}
            setShowDBErrors={setShowDBErrors}
          />
        );
      case "DuckDB":
        return (
          <Stack>
            <TextInput
              label="Database URL"
              w="20rem"
              placeholder="Enter DuckDB url ending with .db"
              value={step4State.fields.databasePath || ""}
              onChange={(event) =>
                handleInputChange("databasePath", event.currentTarget.value)
              }
              error={
                showDBErrors && !step4State.fields.databasePath
                  ? "This field is required"
                  : ""
              }
              required
            />
            <TextInput
              label="Table name"
              w="20rem"
              placeholder="Enter table name"
              value={step4State.fields.tableName || ""}
              onChange={(event) =>
                handleInputChange("tableName", event.currentTarget.value)
              }
              error={
                showDBErrors && !step4State.fields.tableName
                  ? "This field is required"
                  : ""
              }
              required
            />
          </Stack>
        );
      case "OpenSearch":
        return (
          <Stack>
            <TextInput
              label="Host URL"
              w="20rem"
              placeholder="your-opensearch-url"
              value={step4State.fields.hostUrl || ""}
              onChange={(event) =>
                handleInputChange("hostUrl", event.currentTarget.value)
              }
              error={
                showDBErrors && !step4State.fields.hostUrl
                  ? "This field is required"
                  : ""
              }
              required
            />
            <TextInput
              label="Host port"
              w="20rem"
              placeholder="9200"
              value={step4State.fields.hostPort || ""}
              onChange={(event) =>
                handleInputChange("hostPort", event.currentTarget.value)
              }
              error={
                showDBErrors && !step4State.fields.hostPort
                  ? "This field is required"
                  : ""
              }
              required
            />
            <TextInput
              label="Index name"
              w="20rem"
              placeholder="Enter index name"
              value={step4State.fields.indexName || ""}
              onChange={(event) =>
                handleInputChange("indexName", event.currentTarget.value)
              }
              error={
                showDBErrors && !step4State.fields.indexName
                  ? "This field is required"
                  : ""
              }
              required
            />
          </Stack>
        );
      case "Qdrant":
        return (
          <Stack>
            <TextInput
              label="Cluster endpoint"
              w="20rem"
              placeholder="https://qdrant-cluster-endpoint"
              value={step4State.fields.hostUrl || ""}
              onChange={(event) =>
                handleInputChange("hostUrl", event.currentTarget.value)
              }
              error={
                showDBErrors && !step4State.fields.hostUrl
                  ? "This field is required"
                  : ""
              }
              required
            />
            <TextInput
              label="Collection name"
              w="20rem"
              placeholder="Enter collection name"
              value={step4State.fields.collectionName || ""}
              onChange={(event) =>
                handleInputChange("collectionName", event.currentTarget.value)
              }
              error={
                showDBErrors && !step4State.fields.collectionName
                  ? "This field is required"
                  : ""
              }
              required
            />
          </Stack>
        );
      default:
        return null;
    }
  };

  const targetDatabases = [
    { value: "Pinecone", image: "/databaseLogos/Pinecone-logo.png" },
    {
      value: "OpenSearch",
      image: "/databaseLogos/opensearch-logo.png",
    },
    { value: "Weaviate", image: "/databaseLogos/weaviate-logo-4.svg" },
    { value: "Elasticsearch", image: "/databaseLogos/elasticsearch_logo.png" },
    {
      value: "Qdrant",
      image: "/databaseLogos/Qdrant-Logo.jpg",
    },
    { value: "DuckDB", image: "/databaseLogos/duckdb-logo.png" },
  ];
  // const targetDatabases = [
  //   "Elasticsearch",
  //   "Pinecone",
  //   "Weaviate",
  //   "DuckDB",
  //   "OpenSearch",
  // ];

  const targetDatabasesCards = targetDatabases.map((item) => (
    <Radio.Card
      className="root"
      radius="md"
      value={item.value}
      key={item.value}
      w="12rem"
      p="md"
      h="6rem"
    >
      <Group
        wrap="nowrap"
        align="flex-start"
        h="4rem"
        justify="center"
        gap={10}
      >
        <Radio.Indicator
          styles={{
            indicator: {
              // position: "absolute",
            },
          }}
        />
        <Stack justify="center" h="4rem">
          {item.image && <Image src={item.image} w="8rem"></Image>}
        </Stack>
      </Group>
    </Radio.Card>
  ));

  return (
    <Stack m="lg" p="lg">
      <Stack>
        {/* <Text fw={500} fz="h3">
          Configure target database
        </Text> */}
        {/* <Select
          label="Select target database"
          placeholder="Select target"
          data={targetDatabases}
          w="20rem"
          onChange={(value) =>
            setStep4State((prevState: any) => ({
              ...prevState,
              targetDatabase: value,
              fields: {}, 
            }))
          }
          value={step4State.targetDatabase}
        /> */}

        <Radio.Group
          value={step4State.targetDatabase}
          onChange={(value) => {
            setStep4State((prevState: any) => ({
              ...prevState,
              targetDatabase: value,
              fields: {}, // Reset fields when a new database is selected
            }));
            setShowDBErrors(false);
          }}
          label="Select target database"
          description={
            <>
              Choose the target database where you want to load the extracted
              data. Configure your database credentials directly in the ETL
              pipeline code created.
            </>
          }
          required
          error={
            showDBErrors && !step4State.targetDatabase
              ? "This field is required"
              : ""
          }
        >
          <Group pt="md" gap="xs">
            {targetDatabasesCards}
          </Group>
        </Radio.Group>
        {renderDatabaseFields()}
        <Text c="dimmed" fz="xs" w="50%">
          Your vector index needs to have {vectorDimensions} dimensions to match
          the number of dimensions used in the {embeddingState} vector embedding
          model. You can change the model (and number of dimensions) directly in
          the generated ETL pipeline.
        </Text>
      </Stack>
    </Stack>
  );
}
