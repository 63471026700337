import {
  Anchor,
  Button,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { IpynbRenderer } from "react-ipynb-renderer";
import { uploadToDrive } from "../../apiService";
import useNotebookStore from "../../store/useNotebookStore";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { IconExternalLink } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import "react-ipynb-renderer/dist/styles/monokai.css";
import ReactGA from "react-ga4";

export const NotebookViewer = () => {
  const { localNotebook, colabNotebook } = useNotebookStore();

  const [colabLink, setColabLink] = useState<string | null>(null);
  const [uploadingToColab, setUploadingToColab] = useState<boolean>(false);

  const [cookies] = useCookies(["svSession", "_wixAB3|*"], {
    doNotParse: true,
  });

  const paperRef = useRef<HTMLDivElement>(null);
  const [paperHeight, setPaperHeight] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (paperRef.current) {
      setPaperHeight(paperRef.current.clientHeight);
    }
  }, []);
  const handleBack = () => {
    navigate("/docprep");
  };

  const handleDownload = () => {
    ReactGA.event({
      category: "DocPrep",
      action: "[DocPrep]download notebook",
    });
    const notebookContent = JSON.stringify(localNotebook, null, 2);

    const blob = new Blob([notebookContent], { type: "application/json" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "aryn-docprep-pipeline.ipynb";
    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleOpenInColab = async () => {
    try {
      setUploadingToColab(true);
      ReactGA.event({
        category: "DocPrep",
        action: "[DocPrep]open notebook in colab",
      });
      if (!colabLink) {
        const response = await uploadToDrive(colabNotebook, cookies.svSession);
        const fileId = response.data.file_id;
        console.log("response", response);

        const colabUrl = `https://colab.research.google.com/drive/${fileId}`;
        console.log(colabUrl);
        setColabLink(colabUrl);
        window.open(colabUrl, "_blank");
      } else {
        window.open(colabLink, "_blank");
      }
    } catch (error) {
      console.error("Error opening Colab link:", error);
    } finally {
      setUploadingToColab(false);
    }
  };
  return (
    <Stack>
      <Group gap={50} justify="space-between">
        <Stack w="65vw">
          <Text fz="1.5rem" fw={600}>
            ETL pipeline:
          </Text>
          <Paper shadow="xs" withBorder p="xl" ref={paperRef}>
            <IpynbRenderer ipynb={localNotebook} />
          </Paper>
        </Stack>
        <Stack justify="flex-start" w="18vw" py="xl" h={paperHeight} mt={100}>
          <Button
            onClick={handleOpenInColab}
            rightSection={
              uploadingToColab ? (
                <Loader size="sm" />
              ) : (
                <IconExternalLink stroke={2} />
              )
            }
            disabled={uploadingToColab}
          >
            Run in Colab
          </Button>
          <Button onClick={handleDownload}>Download notebook file</Button>
          <Button variant="outline" onClick={handleBack}>
            Back
          </Button>
          <Paper shadow="sm" radius="lg" p="xl">
            <Text ta="center">
              DocPrep pipeline code uses the{" "}
              <Anchor
                href="https://github.com/aryn-ai/sycamore"
                c="#0073bb"
                target="_blank"
              >
                Sycamore library
              </Anchor>{" "}
              for document processing. Customize it for your <br /> use case.
            </Text>
          </Paper>
        </Stack>
      </Group>
    </Stack>
  );
};
