import {
  Anchor,
  Card,
  Divider,
  Group,
  Image,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import UploadZone from "./UploadZone";
import { Dispatch, SetStateAction } from "react";
import { OptionsState } from "../../../types";

const UploadComponent = ({
  setFile,
  loading,
  setLoading,
  openErrorDialog,
  options,
  setOptions,
  setBoxes,
  setJsonData,
  setCallId,
  pageCount,
  setPageCount,
  tempFile,
  setTempFile,
  apiKey,
  setOriginalFile,
  setConvertedFile,
}: {
  setFile: Dispatch<SetStateAction<File | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  openErrorDialog: (message: string) => void;
  options: OptionsState;
  setOptions: Dispatch<SetStateAction<OptionsState>>;
  setBoxes: Dispatch<SetStateAction<any>>;
  setJsonData: Dispatch<SetStateAction<any>>;
  setCallId: Dispatch<SetStateAction<string>>;
  pageCount: number | null;
  setPageCount: Dispatch<SetStateAction<number | null>>;
  tempFile: File | null;
  setTempFile: Dispatch<SetStateAction<File | null>>;
  apiKey: string;
  setOriginalFile: Dispatch<SetStateAction<File | null>>;
  setConvertedFile: Dispatch<SetStateAction<File | null>>;
}) => {
  const theme = useMantineTheme();

  const mobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Stack
      align="center"
      // justify="center"
      py="xl"
      gap="1.5rem"
      // h={mobileScreen ? "calc(100vh - 5rem)" : ""}
    >
      <Stack w={mobileScreen ? "" : "55rem"} gap={0} align="center">
        <Text fz={40} ta="center" fw={700}>
          {/* Chunk Document */}
          {/* Got gnarly PDFs? */}
          Powerful and easy document partitioning
        </Text>
        <Text fz={22} ta="center" fw={300}>
          Try Aryn DocParse on your document
        </Text>
      </Stack>
      {/* {!file ? ( */}
      <UploadZone
        setFile={setFile}
        setLoading={setLoading}
        loading={loading}
        openErrorDialog={openErrorDialog}
        options={options}
        setOptions={setOptions}
        tempFile={tempFile}
        setTempFile={setTempFile}
        setBoxes={setBoxes}
        setJsonData={setJsonData}
        setCallId={setCallId}
        pageCount={pageCount}
        setPageCount={setPageCount}
        apiKey={apiKey}
        setOriginalFile={setOriginalFile}
        setConvertedFile={setConvertedFile}
      />
      {/* <Text size="sm" ta="center" fs="italic" fw={200} pt={50}>
      If you want to partition more than 25 pages, use the Aryn
      Partitioner API directly.{" "}
      <Anchor href="" target="_blank" c="#0073bb" fs="normal">
        Learn more
      </Anchor>
    </Text> */}
      {!tempFile &&
        (!mobileScreen ? (
          <>
            <Group gap="10rem" mt="3rem" wrap="nowrap" align="flex-start">
              <Card
                padding="lg"
                radius="md"
                style={{ alignItems: "center" }}
                w="22vw"
              >
                <Card.Section>
                  <Image src="/template-1.png" w="3rem" alt="Template" />
                </Card.Section>
                <Group justify="space-between" mt="md" mb="xs">
                  <Text fw={600} ta="center" size="md">
                    Visualize Segmentation
                  </Text>
                </Group>

                <Text size="md" fw={100} ta="center">
                  View bounding boxes with labels for each chunk in your
                  document.
                </Text>
              </Card>
              <Card
                padding="lg"
                radius="md"
                style={{ alignItems: "center" }}
                w="22vw"
              >
                <Card.Section h="3rem">
                  <Image src="/JsonDownload.png" w="3rem" alt="Download JSON" />
                </Card.Section>
                <Divider />
                <Group justify="space-between" mt="md" mb="xs">
                  <Text fw={600} ta="center" size="md">
                    Download JSON
                  </Text>
                </Group>

                <Text size="md" fw={100} ta="center">
                  Download your chunked document in JSON with extracted
                  metadata.
                </Text>
              </Card>
              <Card
                padding="lg"
                radius="md"
                style={{ alignItems: "center" }}
                w="22vw"
              >
                <Card.Section h="3rem">
                  <Image src="/pdf-docx-ppt-3.png" w="9rem" alt="Template" />
                </Card.Section>
                <Group justify="space-between" mt="md" mb="xs">
                  <Text fw={600} ta="center" size="md">
                    Support for Multiple File Formats
                  </Text>
                </Group>

                <Text size="md" fw={100} ta="center">
                  Partition and visualize content across PDF, DOC(X), PPT(X),
                  JPG, TXT, XLS, and more. Full list{" "}
                  <Anchor
                    c="#0073bb"
                    href="https://sycamore.readthedocs.io/en/stable/aryn_cloud/file_formats_supported.html"
                    target="_blank"
                  >
                    here.
                  </Anchor>
                </Text>
              </Card>
            </Group>
            <Text fw={100} ta="center">
              The Sandbox will process the first 25 pages of your document. If
              you have more than 25 pages, use the Aryn SDK with DocParse.{" "}
              <Anchor
                c="#0073bb"
                href="https://sycamore.readthedocs.io/en/stable/aryn_cloud/aryn_partitioning_service.html"
                target="_blank"
              >
                Learn more.
              </Anchor>
            </Text>
          </>
        ) : (
          <>
            <Text fw={100} ta="center">
              The Sandbox will process the first 25 pages of your document. If
              you have more than 25 pages, use the Aryn SDK with DocParse.{" "}
              <Anchor c="#0073bb" href="https://aryn.ai/" target="_blank">
                Learn more.
              </Anchor>
            </Text>
            <Stack mt="3rem">
              <Card padding="lg" radius="md" style={{ alignItems: "center" }}>
                <Card.Section>
                  <Image src="/template.png" w="3rem" alt="Template" />
                </Card.Section>
                <Group justify="space-between" mt="md" mb="xs">
                  <Text fw={600} ta="center" size="md">
                    Visualize Segmentation
                  </Text>
                </Group>

                <Text size="md" fw={100} ta="center">
                  View bounding boxes with labels for each chunk in your
                  document.
                </Text>
              </Card>
              <Card padding="lg" radius="md" style={{ alignItems: "center" }}>
                <Card.Section h="3rem">
                  <Image src="/JsonDownload.png" w="3rem" alt="Download JSON" />
                </Card.Section>
                <Divider />
                <Group justify="space-between" mt="md" mb="xs">
                  <Text fw={600} ta="center" size="md">
                    Download JSON
                  </Text>
                </Group>

                <Text size="md" fw={100} ta="center">
                  Download your chunked document in JSON with extracted
                  metadata.
                </Text>
              </Card>
            </Stack>
          </>
        ))}
    </Stack>
  );
};
export default UploadComponent;
