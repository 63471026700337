import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@mantine/core/styles.css";
import "shepherd.js/dist/css/shepherd.css";
import { MantineProvider, createTheme } from "@mantine/core";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  fontFamily: "Space Grotesk, sans-serif",
  primaryColor: "aryn-logo",
  colors: {
    "blue-grey": [
      "#eceff1",
      "#cfd8dc",
      "#b0bec5",
      "#90a4ae",
      "#78909c",
      "#607d8b",
      "#546e7a",
      "#455a64",
      "#37474f",
      "#263238",
    ],
    "aryn-logo": [
      "#ddf3fe",
      "#bedde7",
      "#a0c4d1",
      "#7faaba",
      "#6696a9",
      "#4c8497",
      "#407586", // main color
      "#326070",
      "#244d5b",
      "#133743",
    ],
  },
});

root.render(
  <React.StrictMode>
    <MantineProvider theme={theme} withGlobalClasses>
      <App />
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
