import {
  Text,
  Button,
  Image,
  Box,
  Title,
  Stack,
  Card,
  Group,
} from "@mantine/core";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { IconArrowRight } from "@tabler/icons-react";
import ReactGA from "react-ga4";

const PlaygroundOptions = () => {
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(
    ["svSession", "_wixAB3|*"],
    {
      doNotParse: true,
    }
  );

  useEffect(() => {
    (async () => {
      const apiKey = cookies.svSession;
      if (!apiKey) {
        navigate("/");
      }
    })();
  }, [cookies, navigate]);

  return (
    <Box style={{ backgroundSize: "cover", height: "100vh" }}>
      <Stack align="center" justify="center" h="80vh" gap="0.5rem">
        <Image
          radius="md"
          src="/ArynPlayground.png"
          height={60}
          fit="contain"
          ml="-8"
        />
        <Stack gap={5}>
          <Title fz="h2" ta="center">
            Welcome to the Aryn Playground
          </Title>
          <Text fz="sm" c="dimmed" ta="center">
            Choose a tool to get started with your document processing needs
          </Text>
        </Stack>
        <Group mt={20} justify="center">
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            w="25rem"
            h="10.5rem"
          >
            <Stack gap={10} justify="space-between" h="100%">
              <Stack gap={10}>
                <Title fz="h4">DocParse</Title>
                <Text fz="sm" c="dimmed">
                  Extract tables, images and more from your documents in a
                  structured format.
                </Text>
              </Stack>
              <Group justify="flex-end">
                <Button
                  rightSection={<IconArrowRight />}
                  onClick={() => {
                    ReactGA.event({
                      category: "Playground",
                      action: "DocParse option",
                    });
                    navigate("/docparse");
                  }}
                >
                  Get started
                </Button>
              </Group>
            </Stack>
          </Card>
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            w="25rem"
            h="10.5rem"
          >
            <Stack gap={10} justify="space-between" h="100%">
              <Title fz="h4">DocPrep</Title>
              <Text fz="sm" c="dimmed">
                Extract, chunk, and load documents into your preferred vector
                database.
              </Text>
              <Group justify="flex-end">
                <Button
                  rightSection={<IconArrowRight />}
                  onClick={() => {
                    ReactGA.event({
                      category: "Playground",
                      action: "DocPrep option",
                    });
                    navigate("/docprep");
                  }}
                >
                  Get started
                </Button>
              </Group>
            </Stack>
          </Card>
        </Group>
      </Stack>
    </Box>
  );
};

export default PlaygroundOptions;
