import { create } from "zustand";

interface NotebookState {
  localNotebook: any;
  colabNotebook: any;
  setLocalNotebook: (localNotebook: any) => void;
  setColabNotebook: (colabNotebook: any) => void;
}

const useNotebookStore = create<NotebookState>((set: any) => ({
  localNotebook: null,
  colabNotebook: null,
  setLocalNotebook: (localNotebook: any) => set({ localNotebook }),
  setColabNotebook: (colabNotebook: any) => set({ colabNotebook }),
}));

export default useNotebookStore;
