import {
  ActionIcon,
  Button,
  Group,
  Radio,
  Stack,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function Weaviate({
  step4State,
  setStep4State,
  handleInputChange,
  showDBErrors,
  setShowDBErrors,
}: {
  step4State: {
    targetDatabase: string | null;
    fields: any;
  };
  setStep4State: Dispatch<SetStateAction<any>>;
  handleInputChange: (field: string, value: string | boolean) => void;
  showDBErrors: boolean;
  setShowDBErrors: Dispatch<SetStateAction<boolean>>;
}) {
  const [weaviateClientArgsFields, setWeaviateClientArgsFields] = useState([
    { key: "", value: "" },
  ]);

  useEffect(() => {
    if (step4State.fields) {
      if (step4State.fields.weaviateClientArgs) {
        const initialFields = Object.entries(
          step4State.fields.weaviateClientArgs
        ).map(([key, value]) => ({
          key,
          value: String(value),
        }));
        setWeaviateClientArgsFields(initialFields);
      }
    }
  }, [step4State.fields]);

  const handleWeaviateClientArgsChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const updatedFields = weaviateClientArgsFields.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setWeaviateClientArgsFields(updatedFields);

    setStep4State((prevState: any) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        weaviateClientArgs: Object.fromEntries(
          updatedFields.map(({ key, value }) => [key, value])
        ),
      },
    }));
  };

  const addWeaviateClientArgsField = () => {
    setWeaviateClientArgsFields([
      ...weaviateClientArgsFields,
      { key: "", value: "" },
    ]);
  };

  const removeWeaviateClientArgsField = (index: number) => {
    const updatedFields = weaviateClientArgsFields.filter(
      (_, i) => i !== index
    );
    setWeaviateClientArgsFields(updatedFields);

    setStep4State((prevState: any) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        weaviateClientArgs: Object.fromEntries(
          updatedFields.map(({ key, value }) => [key, value])
        ),
      },
    }));
  };
  return (
    <>
      <Stack justify="space-between">
        <Stack gap="lg" w="25rem">
          <TextInput
            label="Collection name"
            w="20rem"
            placeholder="Enter weaviate collection name"
            value={step4State.fields.collectionName || ""}
            onChange={(event) =>
              handleInputChange("collectionName", event.currentTarget.value)
            }
            error={
              showDBErrors && !step4State.fields.collectionName
                ? "This field is required"
                : ""
            }
            required
          />
          <Radio.Group
            label="Flatten properties"
            onChange={(value) => {
              handleInputChange("flattenProperties", value);
            }}
            error={
              showDBErrors && !step4State.fields.flattenProperties
                ? "This field is required"
                : ""
            }
            required
          >
            <Group mt="xs">
              <Radio value="True" label="True" />
              <Radio value="False" label="False" />
            </Group>
          </Radio.Group>
        </Stack>
        <Stack gap="sm" w="25rem" mt="lg">
          <Text fw={500} fz="h4">
            Weaviate Client Arguments
          </Text>
          <TextInput
            label="HTTP host"
            w="20rem"
            placeholder="Enter weaviate http host"
            value={step4State.fields.http_host || ""}
            onChange={(event) =>
              handleInputChange("http_host", event.currentTarget.value)
            }
            error={
              showDBErrors && !step4State.fields.http_host
                ? "This field is required"
                : ""
            }
            required
          />
          <TextInput
            label="HTTP port"
            w="20rem"
            placeholder="Enter weaviate http port"
            value={step4State.fields.http_port || ""}
            onChange={(event) =>
              handleInputChange("http_port", event.currentTarget.value)
            }
            error={
              showDBErrors && !step4State.fields.http_port
                ? "This field is required"
                : ""
            }
            required
          />
          {/* <TextInput
            label="Http secure"
            w="20rem"
            value={step4State.fields.collectionName || ""}
            onChange={(event) =>
              handleInputChange("http_secure", event.currentTarget.value)
            }
          /> */}
          <Radio.Group
            name="http_secure"
            label="HTTP secure"
            value={step4State.fields.http_secure}
            onChange={(_value) => {
              handleInputChange("http_secure", _value);
            }}
            error={
              showDBErrors && !step4State.fields.http_secure
                ? "This field is required"
                : ""
            }
            required
          >
            <Group mt="xs">
              <Radio value="True" label="True" />
              <Radio value="False" label="False" />
            </Group>
          </Radio.Group>

          <TextInput
            label="GRPC host"
            w="20rem"
            placeholder="Enter weaviate grpc host"
            value={step4State.fields.grpc_host || ""}
            onChange={(event) =>
              handleInputChange("grpc_host", event.currentTarget.value)
            }
            error={
              showDBErrors && !step4State.fields.grpc_host
                ? "This field is required"
                : ""
            }
            required
          />
          <TextInput
            label="GRPC port"
            w="20rem"
            placeholder="Enter weaviate grpc port"
            value={step4State.fields.grpc_port || ""}
            onChange={(event) =>
              handleInputChange("grpc_port", event.currentTarget.value)
            }
            error={
              showDBErrors && !step4State.fields.grpc_port
                ? "This field is required"
                : ""
            }
            required
          />
          <Radio.Group
            name="grpc_secure"
            label="GRPC secure"
            value={step4State.fields.grpc_secure}
            onChange={(_value) => {
              handleInputChange("grpc_secure", _value);
            }}
            error={
              showDBErrors && !step4State.fields.grpc_secure
                ? "This field is required"
                : ""
            }
            required
          >
            <Group mt="xs">
              <Radio value="True" label="True" />
              <Radio value="False" label="False" />
            </Group>
          </Radio.Group>
          {/* <TextInput
            label="Grpc secure"
            w="20rem"
            value={step4State.fields.grpc_secure || ""}
            onChange={(event) =>
              handleInputChange("grpc_secure", event.currentTarget.value)
            }
          /> */}
          {/* {weaviateClientArgsFields.map((field, index) => (
            <Group key={index} wrap="nowrap" align="center">
              <TextInput
                label="Key"
                placeholder="e.g., http_host"
                value={field.key}
                onChange={(event) =>
                  handleWeaviateClientArgsChange(
                    index,
                    "key",
                    event.currentTarget.value
                  )
                }
              />
              <TextInput
                label="Value"
                placeholder="e.g., localhost"
                value={field.value}
                onChange={(event) =>
                  handleWeaviateClientArgsChange(
                    index,
                    "value",
                    event.currentTarget.value
                  )
                }
              />
              <ActionIcon
                color="red"
                onClick={() => removeWeaviateClientArgsField(index)}
                mt={18}
              >
                <IconTrash size="1rem" />
              </ActionIcon>
            </Group>
          ))}
          <Button onClick={addWeaviateClientArgsField}>
            Add Client Argument
          </Button> */}
        </Stack>
        <Stack>
          {/* <Switch
            styles={{
              labelWrapper: {
                paddingRight: "24px",
              },
            }}
            label="Flatten properties"
            labelPosition="left"
            checked={step4State.fields.flattenProperties || false}
            onChange={(event) =>
              handleInputChange(
                "flattenProperties",
                event.currentTarget.checked
              )
            }
          /> */}
        </Stack>
      </Stack>
    </>
  );
}
