import Shepherd from "shepherd.js";
import "./styles.css";
import { IconX } from "@tabler/icons-react";

export const tour = new Shepherd.Tour({
  useModalOverlay: true,
  defaultStepOptions: {
    classes: "shadow-md bg-purple-dark",
  },
  exitOnEsc: true,
});

tour.addStep({
  id: "options-step",
  text: "<div display='flex' flex-direction='column'><div style='font-weight: 700;font-size: 18px;padding-bottom: 10px;'>Tip:</div><div>To adjust the output, you can try out new options and repartition your document. For some PDFs, enabling OCR can improve table extraction performance. Use the tool tips to learn more about each option.</div></div>",
  attachTo: {
    element: ".options",
    on: "left",
  },
  classes: "options-step",
  buttons: [
    {
      text: "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#407586' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-tabler icons-tabler-outline icon-tabler-x'><path stroke='none' d='M0 0h24v24H0z' fill='none' /><path d='M18 6l-12 12' /><path d='M6 6l12 12' /></svg>",
      action: tour.cancel,
    },
  ],
});

document.body.addEventListener("click", (event) => {
  const target = event.target as Element;
  if (
    target?.matches(
      ".shepherd-modal-is-visible.shepherd-modal-overlay-container path"
    )
  ) {
    tour.cancel();
  }
});
