import { Group, Image, Stack, Text, Title } from "@mantine/core";

export const ETLHeader = () => {
  return (
    <Group
      wrap="nowrap"
      justify="space-evenly"
      gap="6rem"
      style={{
        backgroundColor: "#ddf3fe",
      }}
      w="100vw"
      py="xl"
      px="5vw"
      align="center"
    >
      <Stack w="30vw">
        <Title fz={60}>
          {/* Welcome to{" "} */}
          <Text
            inherit
            // variant="gradient"
            c="#407586"
            component="span"
            // gradient={{ from: "darkgreen", to: "teal" }}
          >
            DocPrep
          </Text>
        </Title>
        <Text mt={20} fz="md">
          Extract, chunk, and load your documents into the vector database of
          your choice. Run ETL pipeline in a hosted Google Colab notebook or
          locally.
        </Text>
      </Stack>
      <Image src="/DocPrepFlow.png" w="50vw"></Image>
    </Group>
  );
};
