import {
  ActionIcon,
  Button,
  Collapse,
  Group,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { Input } from "./steps/Input";
import { Embedding } from "./steps/Embedding";
import { IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";
import { PartitioningOptions } from "./steps/PartitioningOptions";
import { Chunking } from "./steps/Chunking";
import { Database } from "./steps/Database";
import useNotebookStore from "../../store/useNotebookStore";
import { useEffect, useState } from "react";
import { OptionsState } from "../../types";
import { useDisclosure } from "@mantine/hooks";
import { generateNotebook } from "../../utils/etlUtils";
import { useNavigate } from "react-router-dom";
import useNotebookOptionsStore from "../../store/useNotebookOptionsStore";
import ReactGA from "react-ga4";

export const NotebookBuilder = () => {
  const { setLocalNotebook, setColabNotebook } = useNotebookStore();

  const notebookOptions = useNotebookOptionsStore();

  const [inputState, setInputState] = useState<any>(notebookOptions.inputState);
  const [optionsState, setOptionsState] = useState<OptionsState>(
    notebookOptions.optionsState
  );
  const [chunkingState, setChunkingState] = useState<{
    strategy: string | null;
    maxTokens: number | undefined;
  }>(notebookOptions.chunkingState);
  const [databaseState, setDatabaseState] = useState<any>(
    notebookOptions.databaseState
  );
  const [embeddingState, setEmbeddingState] = useState<string>(
    notebookOptions.embeddingState
  );

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [showDBErrors, setShowDBErrors] = useState<boolean>(false);

  const [opened, { toggle }] = useDisclosure(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("notebookOptions", notebookOptions);
  }, []);

  const handleGenerate = () => {
    if (!validate()) return;
    ReactGA.event({
      category: "DocPrep",
      action: "[Docprep]generate notebook",
    });
    setLocalNotebook(
      generateNotebook(
        inputState,
        optionsState,
        chunkingState,
        databaseState,
        embeddingState,
        "local"
      )
    );
    setColabNotebook(
      generateNotebook(
        inputState,
        optionsState,
        chunkingState,
        databaseState,
        embeddingState,
        "colab"
      )
    );
    window.scrollTo(0, 0);
    notebookOptions.setInputState(inputState);
    notebookOptions.setOptionsState(optionsState);
    notebookOptions.setChunkingState(chunkingState);
    notebookOptions.setDatabaseState(databaseState);
    notebookOptions.setEmbeddingState(embeddingState);
    navigate("/docprep/notebook");
  };

  const validate = () => {
    console.log(inputState, optionsState, chunkingState, databaseState);

    if (
      !inputState.fileType ||
      !inputState.sourceLocation ||
      (inputState.sourceLocation !== "colab" && !inputState.filePath)
    ) {
      setShowErrors(true);
      return false;
    }
    setShowErrors(false);

    if (!databaseState.targetDatabase) {
      setShowDBErrors(true);
      return false;
    }

    if (
      databaseState.targetDatabase === "OpenSearch" &&
      (!databaseState.fields.hostUrl ||
        !databaseState.fields.hostPort ||
        !databaseState.fields.indexName)
    ) {
      setShowDBErrors(true);
      return false;
    }
    if (
      databaseState.targetDatabase === "Qdrant" &&
      (!databaseState.fields.hostUrl || !databaseState.fields.collectionName)
    ) {
      setShowDBErrors(true);
      return false;
    }
    if (
      databaseState.targetDatabase === "Elasticsearch" &&
      (!databaseState.fields.hostUrl || !databaseState.fields.indexName)
    ) {
      setShowDBErrors(true);
      return false;
    }
    if (
      databaseState.targetDatabase === "Pinecone" &&
      (!databaseState.fields.cloudProvider ||
        !databaseState.fields.indexName ||
        !databaseState.fields.region)
    ) {
      setShowDBErrors(true);
      return false;
    }
    if (
      databaseState.targetDatabase === "Weaviate" &&
      (!databaseState.fields.http_host ||
        !databaseState.fields.http_port ||
        !databaseState.fields.http_secure ||
        !databaseState.fields.grpc_host ||
        !databaseState.fields.grpc_port ||
        !databaseState.fields.grpc_secure ||
        !databaseState.fields.collectionName ||
        !databaseState.fields.flattenProperties)
    ) {
      setShowDBErrors(true);
      return false;
    }
    if (
      databaseState.targetDatabase === "DuckDB" &&
      (!databaseState.fields.databasePath || !databaseState.fields.tableName)
    ) {
      setShowDBErrors(true);
      return false;
    }
    setShowDBErrors(false);
    return true;
  };

  return (
    <Stack p="xl" m="xl" justify="flex-start" gap="xl" pos="relative">
      <Paper shadow="md" p="xl" radius="lg">
        <Text fz="1.5rem" fw={600}>
          Step 1: Select document type and source
        </Text>
        <Input
          step1State={inputState}
          setStep1State={setInputState}
          showErrors={showErrors}
        />
      </Paper>
      <Paper shadow="md" p="xl" radius="lg">
        <Stack>
          <Text fz="1.5rem" fw={600}>
            Step 2: Select transforms and chunking options
          </Text>
          <Stack m="lg" p="lg" gap="xl">
            <Embedding
              embeddingState={embeddingState}
              setEmbeddingState={setEmbeddingState}
            />
            <Group justify="flex-start" onClick={toggle}>
              <Text fz="1rem" fw={600}>
                Partitioning and chunking configuration (optional)
              </Text>
              <ActionIcon variant="transparent" size="xl" onClick={toggle}>
                {opened ? (
                  <IconCircleMinus stroke={2.5} />
                ) : (
                  <IconCirclePlus stroke={2.5} />
                )}
              </ActionIcon>
            </Group>
            <Collapse in={opened} transitionDuration={1000}>
              <Stack>
                <Paper shadow="md" p="sm" radius="lg">
                  <PartitioningOptions
                    step2State={optionsState}
                    setStep2State={setOptionsState}
                  />
                </Paper>
                <Paper shadow="md" p="sm" radius="lg">
                  <Chunking
                    step3State={chunkingState}
                    setStep3State={setChunkingState}
                  />
                </Paper>
              </Stack>
            </Collapse>
          </Stack>
        </Stack>
      </Paper>
      <Paper shadow="md" p="xl" radius="lg">
        <Text fz="1.5rem" fw={600}>
          Step 3: Configure database connector
        </Text>
        <Database
          step4State={databaseState}
          setStep4State={setDatabaseState}
          showDBErrors={showDBErrors}
          setShowDBErrors={setShowDBErrors}
          embeddingState={embeddingState}
        />
      </Paper>
      <Group w="100%" justify="flex-end">
        <Button onClick={handleGenerate} size="md">
          Generate pipeline
        </Button>
      </Group>
    </Stack>
  );
};
