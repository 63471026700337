import { Stack } from "@mantine/core";
import { ETLHeader } from "../../components/ETL/ETLHeader";
import { NotebookViewer } from "../../components/ETL/NotebookViewer";
import useNotebookStore from "../../store/useNotebookStore";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function EtlViewPage() {
  console.log("EtlViewPage is rendering");

  const navigate = useNavigate();
  const { localNotebook } = useNotebookStore();
  useEffect(() => {
    if (!localNotebook) {
      navigate("/docprep");
    }
  }, [navigate, localNotebook]);

  return (
    <Stack align="center">
      <ETLHeader />
      {localNotebook ? <NotebookViewer /> : <div>No notebook available...</div>}
    </Stack>
  );
}
