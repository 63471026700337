import { Dispatch, SetStateAction, useState } from "react";
import { Checkbox, Select, Stack, Text, TextInput } from "@mantine/core";

import "./styles/styles.css";

const DEFAULTINPUT = {
  fileType: "pdf",
  sourceLocation: "s3",
  filePath: "s3://aryn-public/ntsb/59.pdf",
};

export function Input({
  step1State,
  setStep1State,
  showErrors,
}: {
  step1State: {
    fileType: string | null;
    sourceLocation: string | null;
    filePath: string;
  };
  setStep1State: Dispatch<
    SetStateAction<{
      fileType: string | null;
      sourceLocation: string | null;
      filePath: string;
    }>
  >;
  showErrors: boolean;
}) {
  // const sourceOptions = [
  //   { value: "s3", label: "S3 bucket" },
  //   { value: "colab", label: "Colab in-notebook storage" },
  // ];
  // const fileOptions = [
  //   { value: "pdf", label: "PDF" },
  //   { value: "doc", label: "Doc" },
  // ];

  const [sampleDocument, setSampleDocument] = useState<boolean>(false);

  const fileOptions = [
    {
      value: "pdf",
      image: "/red-pdf-file-format-24084.png",
      label: "PDF",
    },
    {
      value: "doc",
      image: "/blue-word-document-doc-24085.png",
      label: "DOC",
    },
    {
      value: "docx",
      image: "/blue-word-document-docx-24086.png",
      label: "DOCX",
    },
    {
      value: "ppt",
      image: "/powerpoint-ppt-file-format-extension-24092.png",
      label: "PPT",
    },
    { value: "pptx", label: "PPTX" },
  ];

  const sourceOptions = [
    {
      value: "s3",
      label: "Amazon S3 bucket",
      image: "/storage-amazon-s3-bucket-with-objects.247x256.png",
    },
    { value: "local", label: "Local file", image: "/folder-1484.png" },
    {
      value: "colab",
      label: "Upload file in Colab notebook",
      image: "/folder-1484.png",
    },
  ];

  return (
    <Stack m="lg" p="lg">
      <Stack align="flex-start" gap="lg">
        <Checkbox
          checked={sampleDocument}
          onChange={(event) => {
            setStep1State(DEFAULTINPUT);
            setSampleDocument(event.currentTarget.checked);
          }}
          label="Use a sample document"
        />
        <Select
          label="Select file type"
          placeholder="Select type"
          data={fileOptions}
          w="20rem"
          onChange={(_value) => {
            setSampleDocument(false);
            setStep1State((prev) => ({
              ...prev,
              fileType: _value,
            }));
          }}
          value={step1State.fileType}
          required
          error={
            showErrors && !step1State.fileType ? "File type is required" : ""
          }
        />
        <Select
          label="Select source location"
          placeholder="Select source"
          data={sourceOptions}
          w="20rem"
          onChange={(_value) => {
            setSampleDocument(false);
            setStep1State((prev) => ({
              ...prev,
              sourceLocation: _value,
              filePath: "",
            }));
          }}
          value={step1State.sourceLocation}
          required
          error={
            showErrors && !step1State.sourceLocation
              ? "Source location is required"
              : ""
          }
        />
        {step1State.sourceLocation === "s3" && (
          <>
            <TextInput
              label="S3 URI"
              placeholder="s3://your-bucket-name/your-file-path"
              value={step1State.filePath}
              w="20rem"
              onChange={(event) => {
                setSampleDocument(false);
                setStep1State((prev) => ({
                  ...prev,
                  filePath: event.target.value,
                }));
              }}
              required
              error={
                showErrors && !step1State.filePath ? "S3 URI is required" : ""
              }
            />
          </>
        )}
        {step1State.sourceLocation === "local" && (
          <TextInput
            label="Local file path"
            placeholder="Enter the path to your file"
            value={step1State.filePath}
            w="20rem"
            onChange={(event) => {
              setSampleDocument(false);
              setStep1State((prev) => ({
                ...prev,
                filePath: event.target.value,
              }));
            }}
            required
            error={
              showErrors && !step1State.filePath ? "Colab Path is required" : ""
            }
          />
        )}
        {step1State.sourceLocation === "colab" && (
          <Text c="dimmed" size="xs">
            This option adds code in the generated ETL pipeline to choose a file
            to upload to your Colab notebook
          </Text>
        )}
        {/* </Group> */}
      </Stack>
    </Stack>
  );
}
