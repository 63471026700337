import { Dispatch, SetStateAction } from "react";
import {
  Divider,
  Group,
  NumberInput,
  Radio,
  ScrollArea,
  Stack,
  Switch,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import InfoTip from "../../InfoTip";
import { OptionsState } from "../../../types";

export function PartitioningOptions({
  step2State,
  setStep2State,
}: {
  step2State: OptionsState;
  setStep2State: Dispatch<SetStateAction<OptionsState>>;
}) {
  const theme = useMantineTheme();
  const mobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Stack m="lg" p="lg">
      <Text fw={500} fz="h3">
        Choose partitioning options
      </Text>
      <Stack w="40rem">
        <Radio.Group
          name="auto_threshold"
          label="Auto-threshold"
          description="This option dynamically adjusts the threshold for bounding box predictions. When disabled, threshold for bounding boxes can be set manually."
          value={step2State.auto_threshold ? "True" : "False"}
          onChange={(_value) => {
            setStep2State({
              ...step2State,
              auto_threshold: _value === "True",
            });
          }}
        >
          <Group mt="xs">
            <Radio value="True" label="True" />
            <Radio value="False" label="False" />
          </Group>
        </Radio.Group>
        {!step2State.auto_threshold && (
          <NumberInput
            name="threshold"
            label="Threshold"
            description="This sets the threshold for accepting the model's predicted bounding boxes. A lower value results in more bounding boxes, but they may overlap. A higher value reduces the number of overlaps, but may miss legitimate bounding boxes. Values range from 0 to 1."
            styles={{
              input: { width: "10rem" },
              controls: { display: "none" },
            }}
            min={0}
            max={1}
            value={step2State.threshold}
            onChange={(value) =>
              setStep2State({ ...step2State, threshold: Number(value) })
            }
          />
        )}
        <Radio.Group
          name="enable_ocr"
          label="Enable OCR"
          description="This option enables optical character recognition. If false, it will attempt to extract the text from the underlying text layer of the PDF file."
          value={step2State.use_ocr ? "True" : "False"}
          onChange={(_value) => {
            setStep2State({
              ...step2State,
              use_ocr: _value === "True",
            });
          }}
        >
          <Group mt="xs">
            <Radio value="True" label="True" />
            <Radio value="False" label="False" />
          </Group>
        </Radio.Group>
        <Radio.Group
          name="extract_table_structure"
          label="Extract table structure"
          description="This option runs a separate model that extract cells from regions of the document identified as tables. These are stored with the Table element in the JSON output."
          value={step2State.extract_table_structure ? "True" : "False"}
          onChange={(_value) => {
            setStep2State({
              ...step2State,
              extract_table_structure: _value === "True",
            });
          }}
        >
          <Group mt="xs">
            <Radio value="True" label="True" />
            <Radio value="False" label="False" />
          </Group>
        </Radio.Group>
        <Radio.Group
          name="extract_images"
          label="Extract images"
          description="When enabled, each region identified as an image is cropped and attached it to the associated ImageElement (base64 encoded) in the JSON output. It can later be used for further processing (e.g. image summarization)."
          value={step2State.extract_images ? "True" : "False"}
          onChange={(_value) => {
            setStep2State({
              ...step2State,
              extract_images: _value === "True",
            });
          }}
        >
          <Group mt="xs">
            <Radio value="True" label="True" />
            <Radio value="False" label="False" />
          </Group>
        </Radio.Group>
      </Stack>
    </Stack>
  );
}
