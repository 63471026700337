import { create } from "zustand";
import { OptionsState } from "../types";

interface ChunkingState {
  strategy: string | null;
  maxTokens: number | undefined;
}

interface StoreState {
  inputState: any;
  optionsState: OptionsState;
  chunkingState: ChunkingState;
  databaseState: any;
  embeddingState: string;
  setInputState: (inputState: any) => void;
  setOptionsState: (optionsState: OptionsState) => void;
  setChunkingState: (chunkingState: ChunkingState) => void;
  setDatabaseState: (databaseState: any) => void;
  setEmbeddingState: (embeddingState: string) => void;
}

const DEFAULTEMBEDDING = "text-embedding-3-small";

const initialOptionsState: OptionsState = {
  auto_threshold: true,
  threshold: 0.32,
  use_ocr: true,
  ocr_images: false,
  ocr_tables: false,
  extract_table_structure: true,
  extract_images: true,
  selected_pages: [[1, 1]],
  source: "web",
};

const useNotebookOptionsStore = create<StoreState>((set: any) => ({
  inputState: {},
  optionsState: initialOptionsState,
  chunkingState: {
    strategy: "GreedySectionMerger",
    maxTokens: 512,
  },
  databaseState: {},
  embeddingState: DEFAULTEMBEDDING,

  setInputState: (inputState: any) => set({ inputState }),
  setOptionsState: (optionsState: OptionsState) => set({ optionsState }),
  setChunkingState: (chunkingState: ChunkingState) => set({ chunkingState }),
  setDatabaseState: (databaseState: any) => set({ databaseState }),
  setEmbeddingState: (embeddingState: string) => set({ embeddingState }),
}));

export default useNotebookOptionsStore;
