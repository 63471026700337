import {
  ActionIcon,
  Button,
  Group,
  ScrollArea,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function Pinecone({
  step4State,
  setStep4State,
  handleInputChange,
  showDBErrors,
  setShowDBErrors,
}: {
  step4State: {
    targetDatabase: string | null;
    fields: any;
  };
  setStep4State: Dispatch<SetStateAction<any>>;
  handleInputChange: (field: string, value: string | boolean) => void;
  showDBErrors: boolean;
  setShowDBErrors: Dispatch<SetStateAction<boolean>>;
}) {
  const [pineconeArgsFields, setPineconeArgsFields] = useState([
    { key: "", value: "" },
  ]);

  type CloudProvider = "aws" | "gcp" | "azure";

  const cloudProviderRegions = {
    aws: ["us-east-1", "us-west-2", "eu-west-1"],
    gcp: ["us-central1", "europe-west4"],
    azure: ["eastus2"],
  };

  useEffect(() => {
    if (step4State.fields) {
      if (step4State.fields.pineconeArgs) {
        const initialFields = Object.entries(
          step4State.fields.pineconeArgs
        ).map(([key, value]) => ({
          key,
          value: String(value),
        }));
        setPineconeArgsFields(initialFields);
      }
    }
  }, [step4State.fields]);

  const handlePineconeArgsChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const updatedFields = pineconeArgsFields.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setPineconeArgsFields(updatedFields);

    setStep4State((prevState: any) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        pineconeArgs: Object.fromEntries(
          updatedFields.map(({ key, value }) => [key, value])
        ),
      },
    }));
  };

  const addPineconeArgsField = () => {
    setPineconeArgsFields([...pineconeArgsFields, { key: "", value: "" }]);
  };

  const removePineconeArgsField = (index: number) => {
    console.log(index);
    const updatedFields = pineconeArgsFields.filter((_, i) => i !== index);
    console.log(updatedFields);
    setPineconeArgsFields(updatedFields);

    setStep4State((prevState: any) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        pineconeArgs: Object.fromEntries(
          updatedFields.map(({ key, value }) => [key, value])
        ),
      },
    }));
  };
  return (
    <>
      <Stack>
        <TextInput
          label="Index name"
          w="20rem"
          placeholder="Enter index name"
          value={step4State.fields.indexName || ""}
          onChange={(event) =>
            handleInputChange("indexName", event.currentTarget.value)
          }
          error={
            showDBErrors && !step4State.fields.indexName
              ? "This field is required"
              : ""
          }
          required
        />
        <Select
          label="Cloud provider"
          w="20rem"
          placeholder="Select cloud provider"
          data={Object.keys(cloudProviderRegions)}
          onChange={(_value) => {
            if (_value) handleInputChange("cloudProvider", _value);
          }}
          value={step4State.fields.cloudProvider || ""}
          allowDeselect={false}
          error={
            showDBErrors && !step4State.fields.cloudProvider
              ? "This field is required"
              : ""
          }
          required
        />
        {/* <TextInput
          label="Cloud provider"
          w="20rem"
          placeholder="Enter cloud provider"
          value={step4State.fields.cloudProvider || ""}
          onChange={(event) =>
            handleInputChange("cloudProvider", event.currentTarget.value)
          }
          error={
            showDBErrors && !step4State.fields.cloudProvider
              ? "This field is required"
              : ""
          }
          required
        /> */}
        <Select
          label="Region"
          w="20rem"
          placeholder="Select region"
          data={
            step4State.fields.cloudProvider
              ? cloudProviderRegions[
                  step4State.fields.cloudProvider as CloudProvider
                ]
              : []
          }
          onChange={(_value) => {
            if (_value) handleInputChange("region", _value);
          }}
          value={step4State.fields.region || ""}
          allowDeselect={false}
          error={
            showDBErrors && !step4State.fields.region
              ? "This field is required"
              : ""
          }
          required
        />
        {/* <TextInput
          label="Region"
          w="20rem"
          placeholder="Enter region"
          value={step4State.fields.region || ""}
          onChange={(event) =>
            handleInputChange("region", event.currentTarget.value)
          }
          error={
            showDBErrors && !step4State.fields.region
              ? "This field is required"
              : ""
          }
          required
        /> */}
        {/* <TextInput
          label="Dimensions"
          w="20rem"
          placeholder="Enter dimensions"
          value={step4State.fields.dimensions || ""}
          onChange={(event) =>
            handleInputChange("dimensions", event.currentTarget.value)
          }
        /> */}
      </Stack>
      {/* <Stack gap="sm" w="25rem">
        <Text fw={500} fz="h4">
          Additional arguments
        </Text>
        {pineconeArgsFields.map((field, index) => (
          <Group key={index} wrap="nowrap" align="center">
            <TextInput
              label="Key"
              placeholder="e.g., http_host"
              value={field.key}
              onChange={(event) =>
                handlePineconeArgsChange(
                  index,
                  "key",
                  event.currentTarget.value
                )
              }
            />
            <TextInput
              label="Value"
              placeholder="e.g., localhost"
              value={field.value}
              onChange={(event) =>
                handlePineconeArgsChange(
                  index,
                  "value",
                  event.currentTarget.value
                )
              }
            />
            <ActionIcon
              color="red"
              onClick={() => removePineconeArgsField(index)}
              mt={18}
            >
              <IconTrash size="1rem" />
            </ActionIcon>
          </Group>
        ))}
        <Button onClick={addPineconeArgsField}>Add Argument</Button>
      </Stack> */}
    </>
  );
}
