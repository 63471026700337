import {
  ActionIcon,
  Affix,
  AppShell,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useEffect } from "react";
import Header from "../../components/Header";
import { IconQuestionMark } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { useCookies } from "react-cookie";
import { Outlet, useNavigate } from "react-router-dom";

export function MainLayout() {
  const theme = useMantineTheme();
  const mobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(
    ["svSession", "_wixAB3|*"],
    {
      doNotParse: true,
    }
  );

  useEffect(() => {
    (async () => {
      const apiKey = cookies.svSession;
      if (!apiKey) {
        navigate("/");
      }
    })();
  }, [cookies, navigate]);

  const handleNeedHelpClick = () => {
    const subject = "Question about the Aryn Playground";
    const body = "";
    const mailtoLink = `mailto:playground@aryn.ai?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <AppShell
      header={{ height: mobileScreen ? "5rem" : "4.5rem" }}
      px="5vw"
      // zIndex={1000}
    >
      <AppShell.Header>
        <Header removeCookie={removeCookie} />
      </AppShell.Header>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
      <Affix position={{ bottom: 15, right: 15 }}>
        <Tooltip label="Need help?">
          <ActionIcon
            size="3rem"
            radius="2rem"
            variant="outline"
            onClick={handleNeedHelpClick}
            style={{
              backgroundColor: "white",
            }}
          >
            <IconQuestionMark size="2rem" stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Affix>
    </AppShell>
  );
}
