import { PDFDocument, rgb } from "pdf-lib";
import CryptoJS from "crypto-js";

export const labelColors = {
  Title: {
    rgb: [255, 192, 0],
    hex: "#FFC000",
  },
  Text: {
    rgb: [0, 176, 80],
    hex: "#00B050",
  },
  "Page-header": {
    rgb: [112, 48, 160],
    hex: "#7030A0",
  },
  "Section-header": {
    rgb: [68, 114, 196],
    hex: "#4472C4",
  },
  table: {
    rgb: [237, 125, 49],
    hex: "#ED7D31",
  },
  "Page-footer": {
    rgb: [166, 166, 166],
    hex: "#A6A6A6",
  },
  Image: {
    rgb: [198, 89, 17],
    hex: "#C65911",
  },
  Formula: {
    rgb: [91, 155, 213],
    hex: "#5B9BD5",
  },
  Footnote: {
    rgb: [128, 128, 128],
    hex: "#808080",
  },
};

export const processAnnotations = (annotations: any) => {
  const processedData: Record<number, any[]> = {};
  annotations.elements.forEach((annotation: any) => {
    const { type, properties, bbox } = annotation;
    const pageNumber = properties.page_number;
    if (!processedData[pageNumber]) {
      processedData[pageNumber] = [];
    }
    processedData[pageNumber].push({
      type,
      bbox,
      annotation,
    });
    if (type === "table" && annotation.table) {
      annotation.table.cells.forEach((token: any) => {
        if (token.bbox) {
          const bboxArray = [
            token.bbox.x1,
            token.bbox.y1,
            token.bbox.x2,
            token.bbox.y2,
          ];
          processedData[pageNumber].push({
            type: "",
            bbox: bboxArray,
          });
        }
      });
    }
  });
  return processedData;
};

export const splitPdf = async (
  file: File,
  ApiKey: string,
  count: number = 25
) => {
  const existingPdfBytes = await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(existingPdfBytes, {
    ignoreEncryption: true,
  });
  const numberOfPages = pdfDoc.getPages().length;
  if (numberOfPages <= count) {
    return file;
  }
  const newPdfDoc = await PDFDocument.create();
  const array: number[] = [];
  for (let i = 0; i < Math.min(count, numberOfPages); i++) {
    array.push(i);
  }
  const copiedPages = await newPdfDoc.copyPages(pdfDoc, array);
  for (let i = 0; i < Math.min(count, numberOfPages); i++) {
    newPdfDoc.addPage(copiedPages[i]);
  }
  const newPdfBytes = await newPdfDoc.save({ updateFieldAppearances: false });
  return new Blob([newPdfBytes], { type: "application/pdf" });
};

export const generatePdf = async (
  file: ArrayBuffer,
  boxes: any,
  count: number = 25
) => {
  if (!file || !boxes) return;
  const pdfDoc = await PDFDocument.load(file);
  const numberOfPages = pdfDoc.getPages().length;
  const newPdfDoc = await PDFDocument.create();
  for (let i = 1; i <= Math.min(count, numberOfPages); i++) {
    const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i - 1]);

    const mediaBox = copiedPage.getMediaBox();

    if (boxes[i]) {
      boxes[i].forEach((box: any) => {
        const type = box.type;
        const bbox = box.bbox;
        const pageWidth = copiedPage.getWidth();
        const pageHeight = copiedPage.getHeight();
        const boxColorHex =
          labelColors[type as keyof typeof labelColors]?.hex || "#FF0000";
        const boxColor = rgb(
          parseInt(boxColorHex.slice(1, 3), 16) / 255,
          parseInt(boxColorHex.slice(3, 5), 16) / 255,
          parseInt(boxColorHex.slice(5, 7), 16) / 255
        );
        copiedPage.drawRectangle({
          x: bbox[0] * pageWidth + mediaBox.x,
          y: (1 - bbox[3]) * pageHeight + mediaBox.y,
          width: (bbox[2] - bbox[0]) * pageWidth,
          height: (bbox[3] - bbox[1]) * pageHeight,
          borderColor: boxColor,
          borderWidth: 1,
        });
        copiedPage.drawText(type, {
          x: bbox[0] * pageWidth + mediaBox.x,
          y: (1 - bbox[1]) * pageHeight + 1 + mediaBox.y,
          size: 8,
          color: boxColor,
        });
      });
    }
    newPdfDoc.addPage(copiedPage);
  }
  const newPdfBytes = await newPdfDoc.save();
  return new Blob([newPdfBytes], { type: "application/pdf" });
};

export const calculateFileHash = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const sha256 = CryptoJS.algo.SHA256.create();

    reader.onload = (event: any) => {
      const chunk = CryptoJS.lib.WordArray.create(event.target.result);
      sha256.update(chunk);

      if (reader.readyState === FileReader.DONE) {
        const hash = sha256.finalize().toString(CryptoJS.enc.Hex);
        resolve(hash);
      }
    };

    reader.onerror = () => reject(new Error("Error reading file"));
    reader.readAsArrayBuffer(file);
  });
};

export const getDimensions = async (
  file: File,
  count: number = 25,
  scale: number = 1
) => {
  if (!file) return;
  const existingPdfBytes = await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const numberOfPages = pdfDoc.getPages().length;
  const dimensions = [];
  for (let i = 1; i <= Math.min(count, numberOfPages); i++) {
    const [copiedPage] = await pdfDoc.copyPages(pdfDoc, [i - 1]);
    const pageWidth = copiedPage.getWidth() * scale;
    const pageHeight = copiedPage.getHeight() * scale;
    dimensions.push({ width: pageWidth, height: pageHeight });
  }
  return dimensions;
};
