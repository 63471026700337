import { Stack } from "@mantine/core";
import { ETLHeader } from "../../components/ETL/ETLHeader";
import { NotebookBuilder } from "../../components/ETL/NotebookBuilder";

export function EtlHomePage() {
  return (
    <Stack align="center">
      <ETLHeader />
      <NotebookBuilder />
    </Stack>
  );
}
