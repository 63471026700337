import {
  ActionIcon,
  Button,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { Dispatch, SetStateAction, useRef, useState } from "react";

const availableKeys = [
  { value: "es_client_args", label: "es_client_args" },
  { value: "wait_for_completion", label: "wait_for_completion" },
  { value: "mappings", label: "mappings" },
  { value: "settings", label: "settings" },
  { value: "execute", label: "execute" },
];

export function Elasticsearch({
  step4State,
  setStep4State,
  handleInputChange,
  showDBErrors,
  setShowDBErrors,
}: {
  step4State: {
    targetDatabase: string | null;
    fields: any;
  };
  setStep4State: Dispatch<SetStateAction<any>>;
  handleInputChange: (field: string, value: string | boolean) => void;
  showDBErrors: boolean;
  setShowDBErrors: Dispatch<SetStateAction<boolean>>;
}) {
  const [elasticsearchArgsFields, setElasticsearchArgsFields] = useState<
    { key: string; value: string }[]
  >([]);
  const [availableDropdownKeys, setAvailableDropdownKeys] =
    useState(availableKeys);
  const viewport = useRef<HTMLDivElement>(null);

  const handleElasticsearchArgsChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const updatedFields = elasticsearchArgsFields.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setElasticsearchArgsFields(updatedFields);

    setStep4State((prevState: any) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        elasticsearchArgs: Object.fromEntries(
          updatedFields.map(({ key, value }) => [key, value])
        ),
      },
    }));
  };

  const scrollToBottom = () =>
    viewport.current?.scrollTo({
      top: viewport.current?.scrollHeight,
      behavior: "smooth",
    });

  const addElasticsearchArgsField = () => {
    scrollToBottom();
    setElasticsearchArgsFields([
      ...elasticsearchArgsFields,
      { key: "", value: "" },
    ]);
  };

  const removeElasticsearchArgsField = (index: number) => {
    const updatedFields = elasticsearchArgsFields.filter((_, i) => i !== index);
    setElasticsearchArgsFields(updatedFields);
    setStep4State((prevState: any) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        elasticsearchArgs: Object.fromEntries(
          updatedFields.map(({ key, value }) => [key, value])
        ),
      },
    }));
    const removedKey = elasticsearchArgsFields[index].key;
    if (removedKey) {
      setAvailableDropdownKeys([
        ...availableDropdownKeys,
        { value: removedKey, label: removedKey },
      ]);
    }
  };
  const handleKeyChange = (index: number, selectedKey: string) => {
    // Update the key for the selected index
    const updatedFields = elasticsearchArgsFields.map((item, i) =>
      i === index ? { ...item, key: selectedKey } : item
    );
    console.log(updatedFields);

    setElasticsearchArgsFields(updatedFields);

    // Remove the selected key from the available keys dropdown to avoid duplicates
    setAvailableDropdownKeys(
      availableDropdownKeys.filter((key) => key.value !== selectedKey)
    );
  };
  return (
    <>
      <Stack>
        <TextInput
          label="Host URL"
          w="20rem"
          placeholder="https://your-elasticsearch-url"
          value={step4State.fields.hostUrl || ""}
          onChange={(event) =>
            handleInputChange("hostUrl", event.currentTarget.value)
          }
          error={
            showDBErrors && !step4State.fields.hostUrl
              ? "This field is required"
              : ""
          }
          required
        />
        <TextInput
          label="Index name"
          w="20rem"
          placeholder="Enter index name"
          value={step4State.fields.indexName || ""}
          onChange={(event) =>
            handleInputChange("indexName", event.currentTarget.value)
          }
          error={
            showDBErrors && !step4State.fields.indexName
              ? "This field is required"
              : ""
          }
          required
        />
      </Stack>
      {/* <Stack gap="sm" w="25rem">
        <Text fw={500} fz="h5">
          Additional arguments
        </Text>
        {elasticsearchArgsFields.map((field, index) => (
          <Group key={index} wrap="nowrap" align="center">
            <TextInput
              label="Key"
              placeholder="e.g., http_host"
              value={field.key}
              onChange={(event) =>
                handleElasticsearchArgsChange(
                  index,
                  "key",
                  event.currentTarget.value
                )
              }
            />
            <TextInput
              label="Value"
              placeholder="e.g., localhost"
              value={field.value}
              onChange={(event) =>
                handleElasticsearchArgsChange(
                  index,
                  "value",
                  event.currentTarget.value
                )
              }
            />
            <ActionIcon
              color="red"
              onClick={() => removeElasticsearchArgsField(index)}
              mt={18}
            >
              <IconTrash size="1rem" />
            </ActionIcon>
          </Group>
        ))}
        <Button onClick={addElasticsearchArgsField}>Add Argument</Button>
      </Stack> */}
    </>
  );
}
