import {
  Button,
  Divider,
  Group,
  NumberInput,
  Stack,
  Switch,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { OptionsState } from "../../../types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import InfoTip from "../../InfoTip";
import { useMediaQuery } from "@mantine/hooks";
import ReactGA from "react-ga4";

const Options = ({
  options,
  setOptions,
  handleReloadBoxes,
  pdfLoading,
}: {
  options: OptionsState;
  setOptions: Dispatch<SetStateAction<OptionsState>>;
  handleReloadBoxes: () => void;
  pdfLoading: boolean;
}) => {
  const theme = useMantineTheme();
  const mobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [initialOptions, setInitialOptions] = useState<OptionsState>(options);
  const [optionsChanged, setOptionsChanged] = useState<boolean>(false);

  useEffect(() => {
    const hasChanged =
      JSON.stringify(options) !== JSON.stringify(initialOptions);
    setOptionsChanged(hasChanged);
  }, [options, initialOptions]);

  useEffect(() => {
    setInitialOptions(options);
  }, []);

  return (
    <Stack className="options" gap="xs" p="xs">
      <Divider py="0.3rem" />
      <Group wrap="nowrap" justify="space-between">
        <Text size="sm">
          Auto-threshold
          <InfoTip message="This option dynamically adjusts the threshold for bounding box predictions. When disabled, threshold for bounding boxes can be set manually." />
        </Text>
        <Switch
          size="sm"
          disabled={pdfLoading}
          checked={options.auto_threshold}
          onChange={(event) => {
            setOptions({
              ...options,
              auto_threshold: event.target.checked,
            });
          }}
        />
      </Group>
      {!options.auto_threshold && (
        <Group wrap="nowrap" justify="space-between">
          <Text size="sm">
            Threshold
            <InfoTip message="This sets the threshold for accepting the model's predicted bounding boxes. A lower value results in more bounding boxes, but they may overlap. A higher value reduces the number of overlaps, but may miss legitimate bounding boxes." />
          </Text>
          <NumberInput
            size={mobileScreen ? "sm" : "xs"}
            placeholder="Threshold"
            min={0}
            max={1}
            step={0.02}
            maw={mobileScreen ? "5rem" : "4rem"}
            value={options.threshold}
            onChange={(value) =>
              setOptions({ ...options, threshold: Number(value) })
            }
            disabled={pdfLoading || options.auto_threshold}
          />
        </Group>
      )}
      <Group wrap="nowrap" justify="space-between">
        <Text size="sm">
          Enable OCR
          <InfoTip message="This option enables optical character recognition. If false, it will attempt to extract the text from the underlying text layer of the PDF file." />
        </Text>
        <Switch
          size="sm"
          checked={options.use_ocr}
          onChange={(event) =>
            setOptions({
              ...options,
              use_ocr: event.target.checked,
              ocr_images: event.target.checked && options.ocr_images,
              ocr_tables: event.target.checked && options.ocr_tables,
            })
          }
          disabled={pdfLoading}
        />
      </Group>
      <Group wrap="nowrap" justify="space-between">
        <Text size="sm">
          Extract Table Structure
          <InfoTip message="This option runs a separate model that extract cells from regions of the document identified as tables. These are stored with the Table element in the JSON output." />
        </Text>
        <Switch
          size="sm"
          checked={options.extract_table_structure}
          onChange={(event) =>
            setOptions({
              ...options,
              extract_table_structure: event.target.checked,
            })
          }
          disabled={pdfLoading}
        />
      </Group>
      <Group wrap="nowrap" justify="space-between">
        <Text size="sm">
          Extract Images
          <InfoTip message="When enabled, each region identified as an image is cropped and attached it to the associated ImageElement (base64 encoded) in the JSON output. It can later be used for further processing (e.g. image summarization)." />
        </Text>
        <Switch
          size="sm"
          checked={options.extract_images}
          onChange={(event) =>
            setOptions({ ...options, extract_images: event.target.checked })
          }
          disabled={pdfLoading}
        />
      </Group>
      <Button
        variant="outline"
        onClick={() => {
          if (optionsChanged) {
            ReactGA.event({
              category: "DocParse",
              action: "[DocParse]repartition",
            });
            setInitialOptions(options);
            handleReloadBoxes();
          }
        }}
        disabled={pdfLoading}
        style={{ whiteSpace: "normal" }}
      >
        Re-partition
      </Button>
    </Stack>
  );
};

export default Options;
