import { Group, Image, Menu, rem, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconArrowRight,
  IconExternalLink,
  IconLogout,
  IconMenu2,
} from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";

const Header = ({ removeCookie }: { removeCookie: any }) => {
  const theme = useMantineTheme();
  const mobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Group
      align="center"
      h={mobileScreen ? "5rem" : "4.5rem"}
      px="5vw"
      justify="space-between"
      style={{
        zIndex: 200,
      }}
      wrap="nowrap"
    >
      <Image
        src="/ArynPlayground.png"
        w={mobileScreen ? "300" : "320"}
        // style={{
        //   cursor: "pointer",
        // }}
      />
      <Menu shadow="md" width={200} position="bottom-end">
        <Menu.Target>
          <IconMenu2 stroke={2} cursor="pointer" />
        </Menu.Target>
        <Menu.Dropdown>
          {location.pathname.includes("docparse") && (
            <Menu.Item
              p="0.5rem 1rem"
              leftSection={
                <IconArrowRight style={{ width: rem(14), height: rem(14) }} />
              }
              component="a"
              onClick={() => navigate("/docprep")}
            >
              <Text size="sm">Aryn DocPrep</Text>
            </Menu.Item>
          )}
          {location.pathname.includes("docprep") && (
            <Menu.Item
              p="0.5rem 1rem"
              leftSection={
                <IconArrowRight style={{ width: rem(14), height: rem(14) }} />
              }
              component="a"
              onClick={() => navigate("/docparse")}
            >
              <Text size="sm">Aryn DocParse</Text>
            </Menu.Item>
          )}
          <Menu.Item
            p="0.5rem 1rem"
            leftSection={
              <IconExternalLink style={{ width: rem(14), height: rem(14) }} />
            }
            component="a"
            href="https://www.aryn.ai"
            target="_blank"
          >
            <Text size="sm">Visit aryn.ai</Text>
          </Menu.Item>
          <Menu.Item
            p="0.5rem 1rem"
            leftSection={
              <IconLogout style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={() => {
              removeCookie("svSession", { path: "/" });
              navigate("/");
            }}
          >
            <Text size="sm">Change API key</Text>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};
export default Header;
