import {
  Paper,
  Text,
  Button,
  Flex,
  Image,
  Center,
  Textarea,
  Box,
  Anchor,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { checkAPIKey } from "../apiService";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const ApiFormPage = () => {
  const [apiKey, setApiKey] = useState("");
  const [error, setError] = useState("");
  const [cookies, setCookie] = useCookies(["svSession", "_wixAB3|*"], {
    doNotParse: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const apiKey = cookies.svSession;
      if (apiKey) {
        navigate("/choose");
      }
    })();
  }, [cookies, navigate]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    ReactGA.event({
      category: "Playground",
      action: "api key entered",
    });
    try {
      const result = await checkAPIKey(apiKey);
      if (!result) {
        setError("Invalid API key");
      } else {
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 6);
        setCookie("svSession", apiKey, {
          path: "/",
          expires: expirationDate,
          secure: true,
          sameSite: "strict",
        });
        if (cookies["_wixAB3|*"] !== false) {
          setCookie("_wixAB3|*", true, {
            path: "/",
            expires: expirationDate,
            secure: true,
            sameSite: "strict",
          });
        }
        navigate("/choose");
      }
    } catch {
      console.log("error signing up");
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <Box style={{ backgroundSize: "cover", height: "100vh" }}>
      <Flex
        align="center"
        justify="center"
        h="80vh"
        direction="column"
        gap="2rem"
      >
        <Paper
          withBorder
          shadow="md"
          px={40}
          py={30}
          mt={30}
          radius="md"
          w="26rem"
        >
          <Image
            radius="md"
            src="/ArynPlayground.png"
            height={70}
            fit="contain"
            ml="-5.5"
          />
          <Center>
            <Text p="sm" fz="sm" ta="center">
              Enter your API key to continue to the Playground
            </Text>
          </Center>
          <form onSubmit={handleSubmit}>
            <Textarea
              placeholder="API key"
              required
              mt="xs"
              value={apiKey}
              onChange={(event) => setApiKey(event.target.value)}
              autosize
              minRows={1}
              maxRows={4}
              error={error}
              onKeyDown={handleKeyPress}
            />
            <Button fullWidth mt="md" type="submit">
              Continue
            </Button>
          </form>
        </Paper>
        <Text size="sm">
          Don’t have an Aryn API key?{" "}
          <Anchor
            c="#0073bb"
            td="underline"
            href="https://www.aryn.ai/get-started"
            target="_blank"
          >
            Sign up
          </Anchor>
        </Text>
      </Flex>
    </Box>
  );
};

export default ApiFormPage;
