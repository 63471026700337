import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PartitionerHomePage from "./pages/Partitioner/PartitionerHomePage";
import { MainLayout } from "./components/MainLayout/MainLayout";
import ApiFormPage from "./pages/ApiFormPage";
import { EtlHomePage } from "./pages/ETL/EtlHomePage";
import PlaygroundOptions from "./pages/PlaygroundOptions";
import { EtlViewPage } from "./pages/ETL/EtlViewPage";
import ReactGA from 'react-ga4';
import { useEffect } from "react";
const TRACKING_ID = "G-YM8M6SF34R";

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
}, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/choose" replace />} />
        <Route path="/" element={<ApiFormPage />} />
        <Route path="/choose" element={<PlaygroundOptions />} />
        <Route element={<MainLayout />}>
          <Route path="/docparse" element={<PartitionerHomePage />} />
          <Route path="/docprep" element={<EtlHomePage />} />
          <Route path="/docprep/notebook" element={<EtlViewPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
