import { Alert, Dialog } from "@mantine/core";
import { useEffect, useState } from "react";
import { initialOptionsState } from "../../apiService";
import { IconAlertCircle } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { OptionsState } from "../../types";
import { useCookies } from "react-cookie";
import UploadComponent from "../../components/Partitioner/upload/UploadComponent";
import PdfViewerComponent from "../../components/Partitioner/view/PdfViewerComponent";

function PartitionerHomePage() {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [boxes, setBoxes] = useState<any>(null);
  const [jsonData, setJsonData] = useState<any>(null);
  const [dialogOpened, dialogHandler] = useDisclosure(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [options, setOptions] = useState<OptionsState>(initialOptionsState);
  const [callId, setCallId] = useState<string>("");
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [tempFile, setTempFile] = useState<File | null>(null);
  const [originalFile, setOriginalFile] = useState<File | null>(null);
  const [convertedFile, setConvertedFile] = useState<File | null>(null);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [cookies, setCookie] = useCookies(["svSession", "_wixAB3|*"], {
    doNotParse: true,
  });

  useEffect(() => {
    (async () => {
      const apiKey = cookies.svSession;
      if (apiKey) {
        setApiKey(apiKey);
      }
    })();
  }, [cookies]);

  const openErrorDialog = (message: string, timeout: number = 15000) => {
    setDialogMessage(message);
    dialogHandler.open();
    setTimeout(() => {
      dialogHandler.close();
    }, timeout);
  };

  const handleNeedHelpClick = () => {
    const subject = "Question about the Aryn Partitioner Sandbox";
    const body = "";
    const mailtoLink = `mailto:playground@aryn.ai?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return apiKey ? (
    <>
      <Dialog
        opened={dialogOpened}
        size="lg"
        radius="md"
        position={{ right: 10, top: 10 }}
        p="none"
        zIndex="1000"
      >
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Error"
          color="red"
          withCloseButton
          onClose={dialogHandler.close}
        >
          {dialogMessage}
        </Alert>
      </Dialog>
      {!(file && !loading) ? (
        <UploadComponent
          setFile={setFile}
          setLoading={setLoading}
          loading={loading}
          openErrorDialog={openErrorDialog}
          options={options}
          setOptions={setOptions}
          setBoxes={setBoxes}
          setJsonData={setJsonData}
          setCallId={setCallId}
          pageCount={pageCount}
          setPageCount={setPageCount}
          tempFile={tempFile}
          setTempFile={setTempFile}
          setOriginalFile={setOriginalFile}
          apiKey={apiKey}
          setConvertedFile={setConvertedFile}
        />
      ) : (
        <PdfViewerComponent
          file={file}
          setFile={setFile}
          options={options}
          setOptions={setOptions}
          jsonData={jsonData}
          setJsonData={setJsonData}
          boxes={boxes}
          setBoxes={setBoxes}
          openErrorDialog={openErrorDialog}
          callId={callId}
          setCallId={setCallId}
          pageCount={pageCount}
          apiKey={apiKey}
          originalFile={originalFile}
          cookies={cookies}
          setCookie={setCookie}
          convertedFile={convertedFile}
        />
      )}
    </>
  ) : (
    <></>
  );
}

export default PartitionerHomePage;
