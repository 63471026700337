import { ActionIcon, Popover, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons-react";

const InfoTip = ({ message }: { message: string }) => {
  const [opened, { close, open }] = useDisclosure(false);
  return (
    <Popover
      width={200}
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
    >
      <Popover.Target>
        <ActionIcon
          onMouseEnter={open}
          onMouseLeave={close}
          size="xs"
          variant="transparent"
          color="gray"
          pb={0}
        >
          <IconInfoCircle />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown style={{ pointerEvents: "none", zIndex: 100 }}>
        <Text size="sm">{message}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};
export default InfoTip;
