import {
  ActionIcon,
  Box,
  Center,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { getDimensions, labelColors } from "../../../utils/partitionerUtils";
import { useDisclosure, useMediaQuery, useViewportSize } from "@mantine/hooks";
import JsonModal from "./JsonModal";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const PDFViewer = ({
  file,
  boxes,
  pdfLoading,
  setDrawerState,
  optionsHeight,
  pageCount,
}: {
  file: File | null;
  boxes: any;
  pdfLoading: boolean;
  setDrawerState: Dispatch<SetStateAction<boolean>>;
  optionsHeight: number;
  pageCount: number | null;
}) => {
  const theme = useMantineTheme();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState(1.2);
  const { width } = useViewportSize();
  const mobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const pagesRefs = useRef<MutableRefObject<HTMLDivElement | null>[]>([]);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<
    { width: number; height: number }[]
  >([]);
  const [jsonData, setJsonData] = useState<any>(null);
  const [pageData, setPageData] = useState<
    {
      width: number;
      height: number;
      originalWidth: number;
      originalHeight: number;
    }[]
  >([]);

  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if (file) {
      getDimensions(file).then((res) => {
        if (res) {
          setDimensions(res);
          console.log("dimensions", res);
        }
      });
    }
  }, [file]);

  useEffect(() => {
    if (mobileScreen) {
      setScale(1);
    } else {
      setScale(1.2);
    }
  }, [mobileScreen]);

  const handleScroll = (data: any) => {
    const pageHeight = pagesRefs.current[0].current?.offsetHeight ?? 0;
    let currentPageIndex = Math.floor(data.y / pageHeight);
    if ((data.y % pageHeight) / pageHeight > 0.75) {
      currentPageIndex += 1;
    }
    setPageNumber(currentPageIndex + 1);
  };

  //   const handleZoomIn = () => {
  //     setScale((prevScale) => prevScale + 0.2);
  //   };

  //   const handleZoomOut = () => {
  //     setScale((prevScale) => Math.max(0.2, prevScale - 0.2));
  //   };

  return (
    <>
      <JsonModal opened={opened} close={close} jsonData={jsonData} />
      <Paper
        withBorder
        style={(theme) => ({
          background: theme.colors.gray[2],
        })}
        w={mobileScreen ? "95vw" : "70vw"}
        pos="relative"
      >
        <ScrollArea
          mih={optionsHeight}
          h="calc(100vh - 4.75rem)"
          // style={{
          //   height: "inherit",
          // }}
          scrollbars="y"
          // offsetScrollbars
          ref={scrollAreaRef}
          type={mobileScreen ? "always" : "hover"}
          // w={mobileScreen ? width : "70vw"}
          onScrollPositionChange={(data) => handleScroll(data)}
          viewportRef={scrollAreaRef}
        >
          <Stack>
            <Group
              justify="space-between"
              wrap="nowrap"
              pos="absolute"
              style={(theme) => ({
                background: theme.colors.gray[5],
                zIndex: 6,
              })}
              w={mobileScreen ? width * 0.9 : "70vw"}
              p="15"
            >
              <Text truncate>{file?.name}</Text>
              {!mobileScreen ? (
                <Group gap="xs" wrap="nowrap">
                  <Text>
                    Page: {pageNumber} / {pageCount}
                  </Text>
                </Group>
              ) : (
                <ActionIcon
                  variant="transparent"
                  c="black"
                  size="lg"
                  onClick={() => setDrawerState((state) => !state)}
                >
                  <IconDots />
                </ActionIcon>
              )}
            </Group>
            <Center mt="3.6rem">
              <LoadingOverlay
                visible={pdfLoading}
                zIndex={10}
                overlayProps={{ radius: "sm", blur: 2 }}
                loaderProps={{ type: "bars" }}
              />

              <Document file={file} renderMode="canvas">
                {[...Array(pageCount)].map((_, pageIndex) => (
                  <div
                    ref={(el) =>
                      (pagesRefs.current[pageIndex] = { current: el })
                    }
                    key={pageIndex}
                  >
                    <Page
                      key={pageIndex + 1}
                      pageNumber={pageIndex + 1}
                      scale={scale}
                      width={mobileScreen ? width * 0.8 : width * 0.7 * 0.7}
                      onRenderSuccess={(pageData) => {
                        setPageData((prev) => {
                          const newPageData = [...prev];
                          newPageData[pageIndex] = pageData;
                          return newPageData;
                        });
                      }}
                      onLoadSuccess={({ height, width }) => {
                        // console.log("loaded", height, width);
                      }}
                    >
                      {/* {pageData[pageIndex] &&
                        dimensions[pageIndex] &&
                        dimensions[pageIndex].width ===
                          pageData[pageIndex].originalWidth &&
                        dimensions[pageIndex].height ===
                          pageData[pageIndex].originalHeight &&
                        boxes &&
                        boxes[pageIndex + 1] &&
                        boxes[pageIndex + 1].map((box: any, index: number) => {
                          const type: string = box.type;
                          const bbox = box.bbox;
                          const color =
                            labelColors[type as keyof typeof labelColors]
                              ?.hex || "#F22625";
                          return (
                            <Box
                              key={index}
                              style={{
                                position: "absolute",
                                left: `${bbox[0] * 100}%`,
                                top: `${bbox[1] * 100}%`,
                                width: `${(bbox[2] - bbox[0]) * 100}%`,
                                height: `${(bbox[3] - bbox[1]) * 100}%`,
                                backgroundColor: "rgba(0, 0, 0, 0)",
                                zIndex: type === "" ? 0 : 5,
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                console.log("clicked", box.annotation);
                                setJsonData(box.annotation);
                                open();
                              }}
                            ></Box>
                          );
                        })} */}
                    </Page>
                    <Divider size="md" />
                  </div>
                ))}
              </Document>
            </Center>
          </Stack>
        </ScrollArea>
      </Paper>
    </>
  );
};
export default PDFViewer;
